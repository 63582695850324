import React from 'react';

import PopoverComponent from '../../../Common/PopoverComponent';
import { DisplaySvg } from '../../../Common/DisplaySvg';

export const BreakdownTitle = ({ title, popoverMessage }) => {
  return (
    <div className="mt-8 text-blue text-13">
      {title}
      <PopoverComponent popoverContent={popoverMessage}>
        <span className="cursor-pointer ml-2">
          <DisplaySvg svgUrl={'/media/svg/ndovu/question-mark-popover.svg'} />
        </span>
      </PopoverComponent>
    </div>
  );
};
