import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { imageLoadingError } from '../../helpers/constants';

export const DisplaySvg = ({ svgUrl, width, height, className }) => {
  return (
    <>
      {svgUrl ? (
        <SVG
          className={className ? className : null}
          src={toAbsoluteUrl(svgUrl)}
          onError={() => console.log(imageLoadingError)}
          style={{
            width: width ? width : null,
            height: height ? height : null,
          }}
        />
      ) : null}
    </>
  );
};
