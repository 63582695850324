/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';

import '../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import '../Auth/styles/registration.scss';

import { ContentRoute } from '../../../_metronic/layout';
import AppFooter from '../../utils/appFooter';
import DisplayAppropriateContent from '../Common/DisplayAppropriateContent';
import ExternaLeft from '../Common/ExternalLeft';
import AdditionalInformation from './Pages/AdditionalInformation';
import AccountType from '../Auth/pages/AccountType';
import AccountDetails from '../Auth/pages/AccountDetails';
import { validateToken } from '../../helpers/validateToken';
import useUserProfile from '../../helpers/CustomHook/useUserProfile';
import logout from '../Auth/pages/Logout';

export const Onboarding = () => {
  const { updateInvestorProfile } = useUserProfile();

  useEffect(() => {
    const validateAndFetchUserProfileInformation = async () => {
      const authToken = validateToken();
      if (!authToken) {
        logout();
      } else {
        await updateInvestorProfile();
      }
    };

    validateAndFetchUserProfileInformation();
  }, [updateInvestorProfile]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Aside*/}
          <ExternaLeft>
            <DisplayAppropriateContent />
          </ExternaLeft>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}

            <div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-15">
              <Switch>
                <ContentRoute path="/onboarding/contact-information">
                  <AdditionalInformation />
                </ContentRoute>

                <ContentRoute path="/onboarding/selectAccountType">
                  <AccountType />
                </ContentRoute>

                <ContentRoute path="/onboarding/accountDetails">
                  <AccountDetails />
                </ContentRoute>
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none justify-content-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                <div className="font-weight-bold text-center">
                  <AppFooter />
                </div>
              </div>
              {/* remove div for links */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};
