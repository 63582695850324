import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import {
  InvestmentAction,
  donutChartcolors,
  investmentType,
  kycStatuses,
  menuNames,
  operationType,
  status,
  transactionType,
} from '../../../helpers/constants';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { messages } from '../../../utils/messages';
import ErrorCard from '../../Common/ErrorCard';
import {
  fetchSingleGoal,
  goalWithdraw,
  deleteEmptyGoal,
} from '../_redux/goalActions';
import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';

import { actions as goalReducerActions } from '../../Goal/_redux/goalReducers';
import Card from '../../Common/FundCard/Card';
import LeftContainer from '../../Common/FundCard/LetfContainer';
import RigthContainer from '../../Common/FundCard/RigthContainer';
import MessageAlert from '../../Common/MessageAlert';
import GoalDetails from '../components/GoalDetails';
import LittleCicle from '../../Invest/components/LittleCicle';
import RiskProfileInformation from '../../Common/RiskProfileInformation';
import Back from '../../Common/BackArrow';
import DonutChart from '../../Invest/components/DonutChart';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorModal from '../../Common/ErrorModal';
import { checkInvestorPermission } from '../../../helpers/checInvestorPermission';
import { PermissionModalContent } from '../../Common/PermissionModalContent';
import { PlanOrGoalInvestmentDetails } from '../components/PlanOrGoalINvestmentDetails';
import {
  CancelButton,
  ConfirmButton,
  TextButton,
} from '../../Common/ReusableButtons';
import useRiskLevels from '../../../helpers/CustomHook/useRiskLevels';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { AdditionalInformationRequiredContent } from '../../Plan/components/AdditionalInformationRequiredContent';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import TextModal from '../../Common/LearnMore';

const ViewGoal = props => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [goal, setGoal] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [withdrawLoading, setWithDrawLoading] = useState();

  const [withdrawalError, setWithdrawalError] = useState(null);
  const [showWithdrawalError, setShowWithdrawalError] = useState(false);
  const [openKycReminderModal, setOpenKycReminderModal] = useState(false);

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [percentages, setPercentages] = useState([]);
  const [labels, setLabels] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);

  const pocketFlow = useSelector(state => state.pocketFlow);
  const investment = useSelector(state => state.investment);

  const { updateRiskLevels, error } = useRiskLevels();

  const { investor, user } = useSelector(state => state.profile);
  const { kycLevel, pendingKyc, kycStatus, investorType } = investor;
  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const handleShow = () => setOpenInfo(true);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    series: [
      {
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      colors: ['#0071CE', '#FF7F30', '#546E7A', '#E91E63', '#FF9800'],
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  });

  const fetchGoal = async () => {
    try {
      setLoading(true);
      const goalResponse = await fetchSingleGoal(id);
      // REMOVING THE DONUT CHART FOR GOAL DISTRIBUTION
      // ⛔️ DO NOT REMOVE THIS CODE, IT MIGHT BE NEEDED IN FUTURE ⛔️

      // const chartRes = await fetchGoalPortfolioPieChartData(id);
      if (goalResponse.status === 200) {
        const goal = goalResponse.data.data;
        setGoal(goal);
        const transaction = goal.transactions.find(
          item =>
            item.transactionStatus === status.PENDING &&
            item.operationType === operationType.WITHDRAW,
        );

        // REMOVING THE DONUT CHART FOR GOAL DISTRIBUTION
        // ⛔️ DO NOT REMOVE THIS CODE, IT MIGHT BE NEEDED IN FUTURE ⛔️

        // if (chartRes.status === 200) {
        //   const dataObj = chartRes.data.data;
        //   const keys = Object.keys(dataObj);
        //   setPercentages(Object.values(dataObj));
        //   setLabels(keys.map(key => firstLetterUpperCase(key)));
        // }

        if (transaction) {
          setWithdrawalError({
            alertMessage: messages.withdraw.unpprovedWithdraw,
            alertMessageType: 'alert-light-danger',
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoal();
    resetGoalInvestmentDtls();
  }, []);

  useEffect(() => {
    updateRiskLevels();
  }, [updateRiskLevels]);

  const resetGoalInvestmentDtls = () => {
    dispatch(goalReducerActions.ResetGoalInvestmentDetails());
  };

  const handleGoalWithdrawals = async () => {
    setWithDrawLoading(true);
    try {
      const response = await goalWithdraw(id);
      const { data: { message } = {} } = response;
      setAlertOn(true);
      setAlert({
        alertMessage: message,
        alertMessageType: 'success',
      });
      setOpen(false);
      setWithDrawLoading(false);
    } catch (err) {
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
      setWithDrawLoading(false);
      setOpen(false);
    }
  };

  const {
    name,
    goalAmount,
    currency,
    targetDate,
    recurringAmount,
    id: goalId,
    transactions,
    goalPortfolioDetails,
    avgYearReturn,
    investmentProfile,
    estimatedBalance,
    withdrawPendingAmount,
    totalWithdrawnAmount,
    staticName,
    depositReceivedAmount,
    depositPendingTransferAmount,
    isDeletable,
  } = goal;

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  let history = useHistory();
  const [show, setShow] = useState(false);
  const [
    openGoalDeletionConfirmation,
    setOpenGoalDeletionConfirmation,
  ] = useState(false);

  if (loading || withdrawLoading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  const topUpGoal = () => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      'investment',
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    if (goal.withdrawPendingAmount > 0) {
      setAlertOn(true);
      setAlert({
        alertMessage:
          'Unable to top up Goal due to a Pending Disinvestment transaction',
        alertMessageType: 'error',
      });
    } else {
      localStorage.removeItem('subscriptionId');
      localStorage.removeItem('promoCode');
      localStorage.removeItem('goalDiscountInfo');
      localStorage.removeItem('discountInfo');

      localStorage.setItem(
        'action',
        JSON.stringify({
          name: 'goalTopUp',
          id: goalId,
        }),
      );

      dispatch(
        pocketActions.updatePocketFlow({
          ...pocketFlow,
          investmentType: investmentType.TOP_UP,
          type: { label: 'Goal', path: 'goal' },
        }),
      );

      dispatch(
        investmentReducerActions.updateInvestmentAction({
          ...investment,
          investmentAction: InvestmentAction.GOAL_TOPUP,
          transactionType: transactionType.GOAL,
          investmentType: investmentType.TOP_UP,
        }),
      );

      dispatch(
        goalReducerActions.updateGoalInvestmentDetails({
          ...reduxGoalInvestmentDtls,
          goalName: name,
          staticGoalId: goalId,
        }),
      );

      const { pathname } = history?.location;

      localStorage.setItem('investingPageEntryPoint', pathname);

      history.push(`/goal/topup/${goalId}`);
    }
  };

  const ErrorModalContent = (
    <>
      {goalPortfolioDetails?.portfolioValue === undefined
        ? 'You can only make withdrawal transactions to a goal you have invested in.'
        : withdrawPendingAmount !== 0
        ? 'Another withdraw transaction has already been requested and is in progress.'
        : 'We can not process your disinvestment request. No amount available in your goal.'}
    </>
  );

  const modalContent = <>{alert?.alertMessage}</>;

  const handleDisinvestButton = () => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      isDeletable ? 'delete_goal' : 'disinvestment',
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    if (isDeletable) return setOpenGoalDeletionConfirmation(true);

    if (
      withdrawPendingAmount !== 0 ||
      goalPortfolioDetails?.portfolioValue === 0 ||
      goalPortfolioDetails?.totalOriginalInvested === undefined
    ) {
      setOpenErrorModal(true);
    } else {
      setOpenErrorModal(false);
      setOpen(true);
    }
  };

  const deleteGoal = async () => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      'delete_goal',
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    try {
      await deleteEmptyGoal(goalId);
      history.push('/goals');
    } catch (error) {
      setOpenGoalDeletionConfirmation(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : error?.alertMessage ? (
        <ErrorCard errorMessage={error?.alertMessage} />
      ) : (
        <>
          <Back text="Back" onClick={() => history.push('/goals')} />
          <CommonSuccessModal
            content={messages.goalWithdraw.body}
            open={open}
            setOpen={() => setOpen(false)}
            moreClasses={'bg-white'}
            buttons={
              <div className="flex-wrap d-flex justify-content-center buttons-gap mt-10 mx-auto">
                <CancelButton
                  buttonText={'Cancel'}
                  handleOnClick={() => setOpen(false)}
                  buttonWidth={'width-140'}
                />

                <ConfirmButton
                  buttonText={'Continue'}
                  handleOnClick={handleGoalWithdrawals}
                  loading={loading}
                  buttonWidth={'width-140'}
                />
              </div>
            }
            addCloseButton={true}
            title={'Notification'}
            showIcon={false}
          />
          {alertOn &&
            (alert.alertMessageType === 'success' ? (
              <CommonSuccessModal
                content={modalContent}
                btnLink="/goals"
                open={alertOn}
                setOpen={() => setAlertOn(false)}
                moreClasses={'bg-white'}
                handleButtonClick={() => {
                  setAlertOn(false);
                  window.location.reload();
                }}
                addCloseButton={true}
              />
            ) : (
              <ErrorModal
                backgroundColor="bg-orange-100"
                open={alertOn}
                content={modalContent}
                setOpen={setAlertOn}
              />
            ))}
          <Card>
            <LeftContainer fundBackground={staticName?.thumbnailLink} />
            <RigthContainer
              name={name}
              classes="flex-column"
              classNames="height-goal"
            >
              <GoalDetails
                currency={currency}
                targetDate={targetDate}
                goalAmount={goalAmount}
                portfolioValue={goalPortfolioDetails?.portfolioValue}
                estimatedBalance={estimatedBalance}
                classes="d-flex flex-column justify-content-between w-100 px-5"
                transactions={transactions}
                averageYtd={avgYearReturn}
                riskLevel={investmentProfile?.riskProfile?.alias}
                recurringAmount={recurringAmount}
              />

              <div
                className={
                  'position-absolute fixed-bottom z-index-1 mb-7 mx-auto'
                }
              >
                <ButtonsContainer
                  justifyContent="justify-content-between px-5"
                  addTopMargin={false}
                  flexWrap="flex-nowrap"
                >
                  <CancelButton
                    buttonText={isDeletable ? 'Delete' : 'Disinvest'}
                    handleOnClick={() => handleDisinvestButton()}
                    loading={loading}
                    twinButton
                  />

                  <ConfirmButton
                    buttonText={'Top Up'}
                    handleOnClick={() =>
                      kycStatus === kycStatuses.PENDING
                        ? setOpenKycReminderModal(true)
                        : topUpGoal()
                    }
                    loading={loading}
                    twinButton
                  />
                </ButtonsContainer>
              </div>

              <br />
              {showWithdrawalError && (
                <MessageAlert
                  closeAlert={() => setShowWithdrawalError(false)}
                  alert={withdrawalError}
                />
              )}
            </RigthContainer>
          </Card>

          <div className="px-7 mt-5 border-radius-8 investment-summary">
            <div className="mt-4 text-center">
              <div className="goal-details-container text-dark">
                <PlanOrGoalInvestmentDetails
                  label={'Total Invested'}
                  value={
                    goalPortfolioDetails?.investedAmount ||
                    goalPortfolioDetails?.investedAmount === 0
                      ? currencyHelper(
                          goalPortfolioDetails?.investedAmount,
                          currency,
                        )
                      : '---'
                  }
                  hasPendingTransactions={
                    depositPendingTransferAmount &&
                    depositPendingTransferAmount > 0
                  }
                  popOverMessage={messages.pendingTransactions.totalInvested}
                  addInformationIcon={true}
                />

                <PlanOrGoalInvestmentDetails
                  label={'Current Value'}
                  value={
                    goalPortfolioDetails?.portfolioValue ||
                    goalPortfolioDetails?.portfolioValue === 0
                      ? currencyHelper(
                          goalPortfolioDetails?.portfolioValue,
                          currency,
                        )
                      : '---'
                  }
                  hasPendingTransactions={
                    depositPendingTransferAmount &&
                    depositPendingTransferAmount > 0
                  }
                  addInformationIcon={true}
                  popOverMessage={messages.pendingTransactions.currentValue}
                />

                <PlanOrGoalInvestmentDetails
                  label={'Disinvested Amount'}
                  value={
                    totalWithdrawnAmount || totalWithdrawnAmount === 0
                      ? currencyHelper(totalWithdrawnAmount, currency)
                      : '---'
                  }
                  addInformationIcon={false}
                />

                {depositReceivedAmount && depositReceivedAmount > 0 ? (
                  <>
                    <PlanOrGoalInvestmentDetails
                      label={'Deposit Received'}
                      value={currencyHelper(depositReceivedAmount, currency)}
                      addInformationIcon={false}
                    />
                  </>
                ) : null}

                {depositPendingTransferAmount &&
                depositPendingTransferAmount > 0 ? (
                  <>
                    <PlanOrGoalInvestmentDetails
                      label={'Payment Instructions Received'}
                      value={currencyHelper(
                        depositPendingTransferAmount,
                        currency,
                      )}
                      addInformationIcon={false}
                    />
                  </>
                ) : null}

                {withdrawPendingAmount && withdrawPendingAmount > 0 ? (
                  <>
                    <PlanOrGoalInvestmentDetails
                      label={'Pending Disinvestment'}
                      value={currencyHelper(withdrawPendingAmount, currency)}
                      addInformationIcon={false}
                    />
                  </>
                ) : null}

                <PlanOrGoalInvestmentDetails
                  label={'Profit'}
                  value={
                    goalPortfolioDetails?.margin
                      ? `${goalPortfolioDetails?.margin.toFixed(2)}%`
                      : goalPortfolioDetails?.margin === 0
                      ? '0%'
                      : '---'
                  }
                  addInformationIcon={false}
                />
              </div>
            </div>
          </div>

          {transactions &&
            transactions.length > 0 &&
            percentages.find(percentage => percentage > 0) && (
              <div className="mt-5 border-radius-8 border-light-blue-545">
                <div className="card-body">
                  <div className="mobile-padding">
                    <div
                      onClick={handleShow}
                      style={{ cursor: 'pointer', width: 'fit-content' }}
                    >
                      <span className="icon-help"></span>{' '}
                      <span className="mt-1 ml-1">Learn More</span>
                    </div>
                  </div>

                  <div className="mobile-padding align-items-sm-center d-flex justify-content-between inverstor-profile-container mx-auto w-100 ">
                    <div className="pie-chart-container">
                      {labels.length > 0 && percentages && (
                        <DonutChart
                          percentages={percentages}
                          colorsArray={donutChartcolors}
                          labels={labels}
                        />
                      )}
                    </div>

                    <div className="risk-profile-container">
                      <div>
                        {labels?.length > 0 &&
                          labels.map(label => {
                            return (
                              <div
                                className="risk-profile-container w-70 d-flex"
                                key={label}
                              >
                                <div>
                                  <LittleCicle
                                    backgroundColor={donutChartcolors[label]}
                                  />
                                </div>
                                <div className="ml-2">
                                  <strong>{label}</strong>: {messages[label]}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="my-10 text-center">
                        <strong>Note:</strong> Your goal investment Profile is{' '}
                        <span style={{ color: '#FF7F30', fontWeight: 'bold' }}>
                          {goal?.investmentProfile?.riskProfile?.alias}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <TextModal
            open={openInfo}
            setOpen={setOpenInfo}
            text={<RiskProfileInformation />}
            title="Learn more about each portfolio."
          />

          {false && (
            <div className="card card-custom gutter-b p-md-20">
              <div id="chart">
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="area"
                  height={300}
                />
              </div>
            </div>
          )}
        </>
      )}
      {openErrorModal && (
        <ErrorModal
          backgroundColor="bg-orange-100"
          open={openErrorModal}
          content={ErrorModalContent}
          setOpen={setOpenErrorModal}
        />
      )}

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<PermissionModalContent />}
        moreClasses={'bg-white'}
        title={'Notification'}
        handleButtonClick={() => setShow(false)}
        addCloseButton={true}
      />

      <CommonSuccessModal
        open={openGoalDeletionConfirmation}
        setOpen={() => setOpenGoalDeletionConfirmation(false)}
        content={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-weight-500">{name}</span> goal
          </span>
        }
        moreClasses={'bg-white'}
        title={'Delete Goal'}
        handleButtonClick={() => setOpenGoalDeletionConfirmation(false)}
        addCloseButton={true}
        showIcon={false}
        buttons={
          <div className="d-flex flex-wrap my-5 buttons-gap mx-auto">
            <CancelButton
              buttonText={'No'}
              handleOnClick={() => setOpenGoalDeletionConfirmation(false)}
              buttonWidth={'width-140'}
            />

            <ConfirmButton
              buttonText={'Yes'}
              handleOnClick={() => deleteGoal()}
              loading={loading}
              buttonWidth={'width-140'}
              disable={loading}
            />
          </div>
        }
      />

      <CommonSuccessModal
        open={openKycReminderModal}
        setOpen={() => setOpenKycReminderModal(false)}
        content={<AdditionalInformationRequiredContent />}
        moreClasses={'bg-white'}
        addTitle={false}
        iconName={'folder'}
        buttons={
          <div className="d-flex flex-column mx-auto mt-5">
            <ConfirmButton
              buttonText={'Next'}
              loading={loading}
              handleOnClick={() => {
                localStorage.setItem(
                  menuNames.MANAGE_ACCOUNT,
                  `/goal/viewGoal/${id}`,
                );

                setOpenKycReminderModal(false);

                history.push(
                  handleKycRouting(kycChecks, '/manage-account', investorType),
                );
              }}
              classes={'mx-auto'}
            />

            <TextButton
              buttonText={'Skip'}
              handleButtonClick={() => {
                setOpenKycReminderModal(false);
                topUpGoal();
              }}
            />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = ({ goal, profile }) => ({
  goal,
  profile,
});

export default connect(mapStateToProps, null)(ViewGoal);
