import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { getConversion } from '../../../helpers/getConversion';
import { serviceProviders } from '../../../helpers/constants';
import { addCommas } from '../../../helpers/handleInput';
import AccountCards from './AccountCards';
import Loader from '../../Common/Loader';

export const SettlementDetailsList = ({
  settlementDetails,
  selectedAccount,
  setSelectedAccount,
}) => {
  const pocketFlow = useSelector(state => state?.pocketFlow);
  const { conversions } = useSelector(state => state?.conversions);
  const pocketDetails = useSelector(state => state?.pocketDetails);
  const { currency: pocketCurrency } = pocketDetails || {};
  const inputAmount = pocketFlow?.inputOne?.amount || 0;

  const [resolvedSettlementDetails, setResolvedSettlementDetails] = useState(
    [],
  );
  const [loading, setLoading] = useState(true);

  const validateSettlementDetailsAccount = async accountDetails => {
    const defaultResponse = { disable: false, errorMessage: null };

    if (!accountDetails) return defaultResponse;

    const {
      serviceProvider,
      maximumWithdrawableAmount,
      currency: { currency, pocketFlatWithdrawalFee } = {},
    } = accountDetails;

    let withdrawalAmount = convertAmountToNumber(pocketFlow?.inputOne?.amount);

    if (pocketCurrency !== currency) {
      const { offeredConversionRate = 1 } =
        (await getConversion(pocketCurrency, currency, conversions)) || {};

      withdrawalAmount = withdrawalAmount * offeredConversionRate;
    }

    // Validate Mpesa limits
    if (
      serviceProvider === serviceProviders.MPESA &&
      withdrawalAmount > maximumWithdrawableAmount
    ) {
      return {
        disable: true,
        errorMessage: `Amount exceeds the allowed Mpesa Limit ${addCommas(
          Number(maximumWithdrawableAmount),
        )} ${currency}`,
      };
    }

    // Validate currency mismatch
    if (currency !== pocketCurrency) {
      const rate = await getConversion(pocketCurrency, currency, conversions);

      if (!rate) {
        return {
          disable: true,
          errorMessage: `Conversion rate not available for ${pocketCurrency} to ${currency}`,
        };
      }

      const withdrawalAmount =
        convertAmountToNumber(inputAmount) * rate?.offeredConversionRate;

      if (withdrawalAmount <= pocketFlatWithdrawalFee) {
        return {
          disable: true,
          errorMessage:
            withdrawalAmount === pocketFlatWithdrawalFee
              ? `The withdrawal amount is equal to transaction fee`
              : `The withdrawal amount is less than transaction fee`,
        };
      }
    }

    // Return default response if no conditions are met
    return defaultResponse;
  };

  useEffect(() => {
    const fetchSettlementDetails = async () => {
      if (settlementDetails?.length > 0) {
        const resolvedDetails = await Promise.all(
          settlementDetails.map(async item => {
            const validation = await validateSettlementDetailsAccount(item);
            return { item, validation };
          }),
        );
        setResolvedSettlementDetails(resolvedDetails);
        setLoading(false);
      }
    };

    fetchSettlementDetails();
  }, [settlementDetails, pocketFlow, conversions, pocketCurrency]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {resolvedSettlementDetails?.length
            ? resolvedSettlementDetails.map(({ item, validation }) => (
                <AccountCards
                  key={item?.id}
                  settlementDetails={item}
                  selected={selectedAccount?.id}
                  checked={item?.id === selectedAccount?.id}
                  disable={validation?.disable}
                  errorMessage={validation?.errorMessage}
                  onPress={() => setSelectedAccount(item)}
                />
              ))
            : null}
        </>
      )}
    </>
  );
};

export default SettlementDetailsList;
