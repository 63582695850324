import React, { useCallback, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import logout from '../modules/Auth/pages/Logout';

const SessionTimeout = () => {
  const [isTimedOut, setIsTimedOut] = useState(false);
  const idleTimer = useRef(null);

  const onAction = useCallback(() => {
    setIsTimedOut(false);
  }, []);

  const onIdle = useCallback(() => {
    if (isTimedOut) {
      logout();
    } else {
      idleTimer.current.reset();
      setIsTimedOut(true);
    }
  }, [isTimedOut]);

  return (
    <IdleTimer
      ref={idleTimer}
      onIdle={onIdle}
      timeout={1000 * 60 * 10}
      startOnMount={true}
      element={document}
      onAction={onAction}
    />
  );
};

export default SessionTimeout;
