import React from 'react';
import Modal from '@material-ui/core/Modal';

import { Buttons } from '../Pocket/components/Buttons';
import Alert from './Alert';
import useResponsiveModalStyles from '../../helpers/modalStyles';
import { DisplaySvg } from './DisplaySvg';

export const ConfirmDeleteModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  loading,
  error,
  title,
  buttonText,
}) => {
  const classes = useResponsiveModalStyles('455px');

  const body = (
    <div
      className={`${classes.modal} ${moreClasses} border-radius-4 py-10 px-5`}
    >
      <div className="d-flex justify-content-start align-items-center flex-column">
        <div>
          <DisplaySvg
            svgUrl={`/media/svg/ndovu/delete-on-popup.svg`}
            width={'72px'}
            height={'72px'}
            className="mb-1"
          />
        </div>

        <div>
          <div className="text-red-600 font-weight-600 text-20 text-center mt-5">
            {title ? title : 'Delete'}
          </div>
          <div className="modal-summary-body text-16 text-center mt-8">
            {content}
            {error?.alertMessage ? (
              <div className="mt-3">
                <Alert alert={error} />{' '}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center my-5"
        style={{ gap: '10px' }}
      >
        <Buttons
          btnText={'Cancel'}
          classes={
            'd-flex justify-content-center px-15 bg-white border-light-blue-600 text-blue font-weight-500 text-13 rounded py-2 mx-auto cursor-pointer mt-4 ml-3'
          }
          handleOnClick={() => setOpen(false)}
        />
        <Buttons
          btnText={buttonText ? buttonText : 'Delete'}
          classes={
            'd-flex justify-content-center px-15 bg-red-600 border-0 text-white font-weight-500 text-13 rounded py-2 mx-auto cursor-pointer mt-4 mr-3'
          }
          handleOnClick={() => handleButtonClick()}
          loading={loading}
          disable={loading}
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
    >
      {body}
    </Modal>
  );
};
