const localStorageError = 'LocalStorage access error: ';

export const getDataFromLocalStorage = key => {
  let storedData;
  try {
    storedData = localStorage && localStorage.getItem(key);

    return storedData;
  } catch (error) {
    console.log(localStorageError, error);
  }
};

export const addDataInLocalStorage = (key, value) => {
  try {
    localStorage && localStorage.setItem(key, value);
  } catch (error) {
    console.log(localStorageError, error);
  }
};

export const removeDataFromLocalStorage = key => {
  try {
    localStorage && localStorage.removeItem(key);
  } catch (error) {
    console.log(localStorageError, error);
  }
};
