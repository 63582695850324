import React from 'react';

const credits = (investedAmount, bonus) => {
  return (
    <div className="d-flex align-items-center justify-content-between text-14 mt-3">
      <div>{investedAmount}</div>
      <div>{bonus}</div>
    </div>
  );
};

export const HowReferralWorks = () => {
  return (
    <div className="pt-6 pb-4">
      <div>
        Referring friends is simple! When you sign up, you can enter a referral
        code from someone who has already joined to earn credits. After signing
        up, you'll receive your own unique referral code and invite link. Share
        these with your friends, and each time they sign up using your code or
        link, you'll both receive Investment credits.
      </div>
      <div className="px-4">
        {credits('5,000-9,999', 'KES 250')}
        {credits('10,000-19,999', 'KES 500')}
        {credits('20,000-49,999', 'KES 750')}
        {credits('50,000 and above', 'KES 1000')}
      </div>
    </div>
  );
};

export const WhyReferFriends = () => {
  return (
    <div className="pt-6 pb-4">
      Referring friends not only rewards you but also helps them make smarter
      financial decisions with ease. By sharing your code, you're encouraging
      others to join a platform that offers high-quality investment solutions
      designed to secure their financial future. Plus, the more friends you
      refer, the more rewards you unlock
    </div>
  );
};
