import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Note } from '../../Common/Note';
import { currencyHelper } from '../../../helpers/currencyHelper';
import CloseModal from '../../Common/CloseModal';
import WithdrawalSliderInput from '../../Common/WithdrawalSliderInput';
import { useDispatch, useSelector } from 'react-redux';
import { actions as withdrawalActions } from '../_redux/portfolioReducer';
import { addCommas } from '../../../helpers/handleInput';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import RangeSlider from '../../Common/sliderRanger';
import { TransactionDetailsItem } from '../../Settlements/components/TransactionDetailsItem';
import useResponsiveModalStyles from '../../../helpers/modalStyles';

const WithdrawAmountModal = ({
  open,
  setOpenWithdrawalModal,
  title,
  fundValue,
  totalUnits,
  setwithdrawPercentage,
  setOpenConfirmModal,
  handleWithdrawals,
  openModal,
  withdrawTheWholeAmount,
  planDetails,
}) => {
  const classes = useResponsiveModalStyles('665px');
  const isMediumScreen = useMediaQuery(
    '(min-width: 501px) and (max-width: 1000px)',
  );

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [withdrawUnits, setWithdrawUnits] = useState(0);
  const [withdrawPerc, setWithdrawPerc] = useState(0);
  const [minWithdrawAmountAchieved, setMinWithdrawAmountAchieved] = useState(
    false,
  );

  const dispatch = useDispatch();

  const withdrawalDetails = useSelector(state => state.withdrawalDetails);

  const { currency, minWithdrawableAmount, isMoneyMarketFund } = planDetails;

  const handleAmountChange = event => {
    let { value } = event.target;
    value = Number(value);
    setWithdrawPerc(value);
    setwithdrawPercentage(value);
    const withdrawAmount = (value * fundValue?.toFixed(2)) / 100;
    setWithdrawAmount(withdrawAmount);
    const toWithdrawUnits = (value * totalUnits) / 100;
    setWithdrawUnits(toWithdrawUnits?.toFixed(2));

    if (withdrawAmount >= minWithdrawableAmount) {
      setMinWithdrawAmountAchieved(true);
    } else {
      setMinWithdrawAmountAchieved(false);
    }
  };

  const openConfirmationModal = () => {
    const withdrawalDetails = {
      unitPercentage: withdrawTheWholeAmount ? 100 : withdrawPerc,
      numberOfUnits: withdrawTheWholeAmount ? totalUnits : withdrawUnits,
      transactionFee: 0,
      approximatedValue: withdrawTheWholeAmount
        ? fundValue
        : currencyHelper(withdrawAmount, currency),
    };

    dispatch(withdrawalActions.updateWithdrawalDetails(withdrawalDetails));

    setOpenWithdrawalModal(false);
    setOpenConfirmModal(true);
  };

  const displayError = () => {
    const subString = '*Minimum disinvest amount is ';

    if (withdrawPerc && !minWithdrawAmountAchieved) {
      return (
        <div className="my-5 withdrw-modal-err">
          {subString}
          {currencyHelper(minWithdrawableAmount, currency)}
        </div>
      );
    }
  };

  const modalContent = (
    <div className={classes.modal}>
      <div className="d-flex justify-content-between align-items-center p-5">
        <div
          className="w-100 text-center text-14 font-weight-600"
          id="simple-modal-title"
        >
          {'Confirm Disinvest Details'}
        </div>
        <CloseModal setOpen={setOpenConfirmModal} />
      </div>

      <div className="divider"></div>

      <div className="px-md-10 px-5 pt-5 pb-10">
        {!isMoneyMarketFund ? (
          <>
            <TransactionDetailsItem
              label={'Unit Percentage'}
              value={`${withdrawalDetails?.unitPercentage} %`}
            />

            <TransactionDetailsItem
              label={'Number of Units'}
              value={`${addCommas(withdrawalDetails?.numberOfUnits)} units`}
            />
          </>
        ) : null}

        <TransactionDetailsItem
          label={'Transaction Fee'}
          value={`0 ${currency}`}
        />

        <TransactionDetailsItem
          label={'Approximated Value'}
          value={`${addCommas(
            withdrawalDetails?.approximatedValue,
          )} ${currency}`}
        />

        <Note
          title={'Note:'}
          content={
            'By completing this action your money will be deposited to your ndovu pocket in 2 business days'
          }
          titleColor="text-primary-orange"
          contentColor="text-primary-orange"
          alignText="left"
        />

        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={() => setOpenConfirmModal(false)}
            twinButton
          />

          <ConfirmButton
            buttonText={'Confirm'}
            handleOnClick={handleWithdrawals}
            twinButton
          />
        </ButtonsContainer>
      </div>
      {/* </MainWrapper> */}
    </div>
  );

  const smallDevice = useMediaQuery('(max-width : 660px)');

  const sliderContainer = smallDevice ? (
    <div className="d-flex flex-column justify-content-between pt-5">
      <div className={`d-flex justify-content-between`}>
        <div className="width-drawal-font mr-5">Unit Percentage</div>
        <div className="goal-amount py-2 text-center">{withdrawPerc} %</div>
      </div>

      <RangeSlider
        handleSlideChange={handleAmountChange}
        sliderValue={withdrawPerc || 1}
        min={0}
        max={100}
        step={10}
        rangeInputName={'withdrawPerc'}
        centerInput={false}
      />
    </div>
  ) : (
    <div className="d-flex flex-row align-items-center justify-content-between pt-5">
      <div className={`w-100 d-flex flex-row justify-content-between`}>
        <div className="width-drawal-font mr-5">Unit Percentage</div>
        <RangeSlider
          handleSlideChange={handleAmountChange}
          sliderValue={withdrawPerc || 1}
          min={0}
          max={100}
          step={10}
          rangeInputName={'withdrawPerc'}
        />
        <div className="goal-amount py-2 text-center">{withdrawPerc} %</div>
      </div>
    </div>
  );

  const body = (
    <div className={classes.modal}>
      <div className="d-flex justify-content-between align-items-center p-5">
        <div
          className="w-100 text-center text-14 font-weight-600"
          id="simple-modal-title"
        >
          {title}
        </div>
        <CloseModal setOpen={setOpenWithdrawalModal} />
      </div>

      <div className="divider"></div>

      {displayError()}
      <div className="my-5 px-5" id="simple-modal-description">
        <div className="mt-5">
          <WithdrawalSliderInput
            handleChange={handleAmountChange}
            rangeInputName="withdrawPerc"
            sliderValue={withdrawPerc}
            min={0}
            max={100}
            step={10}
            input={
              <>
                <div className="d-flex flex-row align-items-center justify-content-between mt-3">
                  <div className="width-drawal-font">Number of Units</div>
                  <div className="goal-amount py-2 text-center">
                    {`${withdrawUnits} units`}
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between mt-5 pb-10">
                  <div className="width-drawal-font">Approximated Value</div>
                  <div>
                    <div className="goal-amount py-2 text-center">
                      {currencyHelper(withdrawAmount, currency)}
                    </div>
                  </div>
                </div>
              </>
            }
            unit={sliderContainer}
          />
          <p className="mt-3 justify-content-start">
            <b>Disclaimer:</b> The value presented above is an approximation by
            ndovu based on previous market prices, this may change on a daily
            basis dependent on the market trading prices.
          </p>
        </div>

        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={() => {
              setOpenWithdrawalModal(false);
            }}
            twinButton
          />

          <ConfirmButton
            buttonText={'Continue'}
            buttonType={'submit'}
            handleOnClick={
              minWithdrawAmountAchieved ? openConfirmationModal : null
            }
            disable={!minWithdrawAmountAchieved}
            twinButton
          />
        </ButtonsContainer>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={`${classes.modalContainer} ${isMediumScreen ? 'mx-7' : ''}`}
      >
        {modalContent}
      </Modal>

      <Modal
        open={open}
        onClose={() => setOpenWithdrawalModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={`${classes.modalContainer} ${isMediumScreen ? 'mx-7' : ''}`}
      >
        {body}
      </Modal>
    </>
  );
};

export default WithdrawAmountModal;
