import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as investmentReducerActions } from '../../_redux/investmentReducers';

import PaymentMethodRadioButton from '../../../Common/RadioButton/paymentMethod';
import {
  InvestmentAction,
  mobileMoneyPaymentChannel,
  paymentMethod,
} from '../../../../helpers/constants';
import Alert from '../../../Common/Alert';
import MainWrapper from '../../../Pocket/components/MainWrapper';
import InvestmentPaymentBreakdown from './PaymentBreakdown';
import './index.scss';
import { fetchPaymentChannels } from '../../_redux/investmentAction';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import Loader from '../../../Common/Loader';
import { ApplyVoucherCode } from '../ApplyVoucherCode';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { ButtonsContainer } from '../../../Common/ButtonsContainer';

const PaymentChannelsAndInvestmentDetails = ({
  setUseVoucher,
  payWithCreditCard,
  discount,
  amount,
  currency,
  subscription,
  planDetails,
  totalAmount,
  setPayWithMobileMoney,
  setPayWithBankTransfer,
  alert,
  alertOn,
  goalInvesting,
  goalData,
  activePaymentMethod,
  setActivePaymentMethod,
  alertRef,
  setpayWithBongaPoints,
  loading,
  setLoading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { investmentAction } = useSelector(state => state.investment) || {};
  const investment = useSelector(state => state.investment);

  const [paymentChannels, setPaymentChannels] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [offeredConversionRate, setOfferedConversionRate] = useState();

  const isPocketTopup = investmentAction === InvestmentAction.POCKET_TOPUP;

  const cancelOperation = () => {
    localStorage.removeItem('discountInfo');
    localStorage.removeItem('goalDiscountInfo');
    localStorage.removeItem('promoCode');

    if (goalInvesting) {
      history.push('/goals');
    } else {
      history.goBack();
    }
  };

  const getPaymentChanelList = async () => {
    setLoading(true);
    try {
      const response = await fetchPaymentChannels(currency);

      const paymentChannelsWithoutPocket = response?.data?.items?.filter(
        paymentChannel =>
          paymentChannel?.value !== paymentMethod.USER_POCKET &&
          !(
            paymentChannel?.value === paymentMethod.BONGA_POINT &&
            !planDetails?.allowBongaPointPayment
          ),
      );

      setPaymentChannels(paymentChannelsWithoutPocket);
      setLoading(false);
    } catch (error) {
      setErrorMessage({
        alertMessage: error?.response?.data?.message,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    currency && getPaymentChanelList();
  }, [currency]);

  const investingAmount = goalInvesting ? goalData?.amount : amount;
  const selectedCurrency = goalInvesting ? goalData?.currency : currency;

  const renderPaymentOptions = () => {
    return (
      <div>
        {errorMessage ? <Alert alert={errorMessage} /> : null}

        <h3 className="mb-5 text-20 font-weight-600 text-center">
          Select a payment method
        </h3>

        {paymentChannels?.map(paymentChannel => (
          <PaymentMethodRadioButton
            key={paymentChannel?.id}
            label={paymentChannel?.name}
            processingDays={
              paymentChannel?.name === paymentMethod.MPESA &&
              investingAmount >
                paymentChannel?.maximumInvestableAmountPerTransaction ? (
                `The maximum amount payable via Mpesa is ${moneyFormat(
                  paymentChannel?.maximumInvestableAmountPerTransaction,
                )} ${selectedCurrency}`
              ) : (
                <span>
                  {`Transfer can take up to`}
                  <span className="font-weight-500 text-dark-550">
                    {' '}
                    {`${paymentChannel?.processingTime}`}
                  </span>{' '}
                  {`to complete`}
                </span>
              )
            }
            activePaymentMethod={activePaymentMethod}
            name={paymentChannel?.value}
            setActivePaymentMethod={setActivePaymentMethod}
            fee={paymentChannel?.transactionFee}
            disable={
              (paymentChannel?.name === paymentMethod.MPESA &&
                investingAmount >
                  paymentChannel?.maximumInvestableAmountPerTransaction) ||
              (paymentChannel?.value === paymentMethod.CARD &&
                investmentAction !== InvestmentAction.POCKET_TOPUP)
            }
          />
        ))}
      </div>
    );
  };

  const dispatchPaymentMethod = () => {
    setLoading(true);

    if (mobileMoneyPaymentChannel.includes(activePaymentMethod)) {
      dispatch(
        investmentReducerActions.updateInvestmentAction({
          ...investment,
          paymentMethod: activePaymentMethod,
        }),
      );
      setPayWithMobileMoney(true);
    } else if (
      activePaymentMethod === paymentMethod.KENYAN_CARD ||
      activePaymentMethod === paymentMethod.INTERNATIONAL_CARD ||
      activePaymentMethod === paymentMethod.CARD
    ) {
      payWithCreditCard(activePaymentMethod);
    } else if (activePaymentMethod === paymentMethod.BANK_TRANSFER) {
      setPayWithBankTransfer(true);
    } else if (activePaymentMethod === paymentMethod.BONGA_POINT) {
      setpayWithBongaPoints(true);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <MainWrapper>
          <div>
            <div className="mx-auto">
              {alertOn && (
                <div ref={alertRef} className="mb-5">
                  <Alert alert={alert} />{' '}
                </div>
              )}

              {renderPaymentOptions()}
              {activePaymentMethod && (
                <InvestmentPaymentBreakdown
                  discount={discount}
                  amount={investingAmount}
                  currency={selectedCurrency}
                  subscription={subscription}
                  planDetails={goalInvesting ? undefined : planDetails}
                  totalAmount={totalAmount}
                  activePaymentMethod={activePaymentMethod}
                  isPocketTopup={isPocketTopup}
                  offeredConversionRate={offeredConversionRate}
                />
              )}
            </div>

            {/* // Plans on offer shouldn't have voucher redemption */}

            {planDetails?.settings?.[0]?.discountable && !isPocketTopup ? (
              <ApplyVoucherCode setUseVoucher={setUseVoucher} />
            ) : null}
          </div>

          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Cancel"
              handleOnClick={cancelOperation}
              twinButton={true}
            />

            <ConfirmButton
              buttonText="Next"
              loading={loading}
              handleOnClick={() => dispatchPaymentMethod()}
              disable={!activePaymentMethod}
              twinButton={true}
            />
          </ButtonsContainer>

          <i className="text-13 text-dark-550 mt-5 pb-5">
            Note: Your bank or mobile operator might apply transfer fees
          </i>
        </MainWrapper>
      )}
    </>
  );
};

const mapStateToProps = ({ profile }) => ({
  profile,
});

export default connect(
  mapStateToProps,
  null,
)(PaymentChannelsAndInvestmentDetails);
