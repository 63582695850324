import './index.scss';

import AppFooter from '../../../utils/appFooter';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DisplaySvg } from '../DisplaySvg';

export default function ExternaLeft({ children, originPartnerLogo }) {
  return (
    <>
      {/*begin::Aside*/}
      <div
        className="login-aside d-flex flex-row-auto py-10"
        style={{
          background: 'rgba(0, 113, 206, 0.15)',
        }}
      >
        {/*begin: Aside Container*/}
        <div className="d-flex flex-column justify-content-start sm-margin-left">
          {/* start:: Aside header */}
          <span className="extenal-ndovu-logo">
            {originPartnerLogo ? (
              <LazyLoadImage
                alt={'Origin Partner logo'}
                src={originPartnerLogo} // use normal <img> attributes as props
                width={'350px'}
              />
            ) : (
              <DisplaySvg
                svgUrl={'/media/svg/ndovu/logo.svg'}
                width="250px"
                className="mb-md-10 mb-lg-10 mb-1"
              />
            )}
          </span>
          {/* end:: Aside header */}

          {/* start:: Aside content */}
          <div className="flex-column-fluid d-flex flex-column hr-margins">
            {children}
          </div>
          {/* end:: Aside content */}

          {/* start:: Aside footer for desktop */}
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            {/* Removed Links here */}
            <div>
              <DisplaySvg svgUrl={'/media/svg/ndovu/footer-logo.svg'} />
              <div>
                <AppFooter />
              </div>
            </div>
          </div>
          {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/}
      </div>
      {/*begin::Aside*/}
    </>
  );
}
