import React, { useEffect, useState } from 'react';

import AddButton from './AddButton';
import {
  deleteSettlementDetails,
  fetchSettlementDetails,
} from '../_redux/withdrawalCrud';
import './Style.scss';

import Loader from '../../Common/Loader';
import { SettlementDetails } from './SettlementDetails';
import { ConfirmDeleteModal } from '../../Common/ConfirmDeleteModal';
import { settlementDetailsType } from '../../../helpers/constants';
import { DisplaySvg } from '../../Common/DisplaySvg';

const SingleDetail = ({ name, linkTo, type, iconName, eventName }) => {
  const [loading, setLoading] = useState(false);
  const [settlementDetailsList, setSettlementDetailsList] = useState(null);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [settlementDetailsId, setSettlementDetailsId] = useState();

  const fetchDetails = async () => {
    try {
      const res = await fetchSettlementDetails();
      setSettlementDetailsList(res?.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleDeleteSettlementDetails = async () => {
    setLoading(true);
    try {
      await deleteSettlementDetails(settlementDetailsId);

      const deletedSettlementDetailsRemoved = settlementDetailsList?.filter(
        settlementDetails => settlementDetails?.id !== settlementDetailsId,
      );
      setSettlementDetailsList(deletedSettlementDetailsRemoved);

      setLoading(false);
      setOpenConfirmDeleteModal(false);
    } catch (error) {
      setLoading(false);
      setAlert({ alertMessage: error?.response?.data?.message });
    }
  };

  if (loading) {
    return <Loader />;
  }

  const noSettlementDetailsCreated =
    'bg-light-blue-40 border-light-blue-600 border-radius-8 px-5 py-8';

  return (
    <div className="mt-2">
      <div
        className={`${
          type === 'bankDetails' ? 'mt-10' : ''
        } ${settlementDetailsList?.length === 0 &&
          noSettlementDetailsCreated} mb-5 d-flex justify-content-between align-items-center`}
      >
        <div className="">
          <DisplaySvg svgUrl={`/media/svg/ndovu/${iconName}.svg`} />
          <span className="title text-back-700 font-weight-500 ml-2">
            {name}
          </span>
        </div>

        <AddButton linkTo={linkTo} eventName={eventName} />
      </div>
      {settlementDetailsList?.map(
        settlementDetail =>
          settlementDetail.type === settlementDetailsType.MOBILE_MONEY &&
          type === 'mobileMoney' && (
            <SettlementDetails
              key={settlementDetail?.id}
              settlementDetail={settlementDetail}
              editSettlementDetailsPageLink={`/withdrawal-details/mobile-money/edit/${settlementDetail?.id}`}
              openConfirmModal={setOpenConfirmDeleteModal}
              settlementDetailsId={settlementDetail?.id}
              setSettlementDetailsId={setSettlementDetailsId}
              setAlert={setAlert}
            />
          ),
      )}
      {settlementDetailsList?.map(
        settlementDetail =>
          settlementDetail.type === 'BANK' &&
          type === 'bankDetails' && (
            <SettlementDetails
              key={settlementDetail?.id}
              settlementDetail={settlementDetail}
              editSettlementDetailsPageLink={`/withdrawal-details/bank-details/edit/${settlementDetail?.id}`}
              openConfirmModal={setOpenConfirmDeleteModal}
              settlementDetailsId={settlementDetail?.id}
              setSettlementDetailsId={setSettlementDetailsId}
              setAlert={setAlert}
            />
          ),
      )}

      <ConfirmDeleteModal
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        moreClasses={'bg-white'}
        content={
          'Are you sure you want to delete this settlement detail? This action can not be undone.'
        }
        handleButtonClick={handleDeleteSettlementDetails}
        loading={loading}
        error={alert}
      />
    </div>
  );
};

export default SingleDetail;
