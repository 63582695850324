import React from 'react';
import { DisplaySvg } from '../../Common/DisplaySvg';

export const SettingsPageLink = ({ pageName, handlePageRedirection }) => {
  return (
    <div
      onClick={handlePageRedirection}
      key={pageName}
      className="border-light-blue-600 bg-light-blue-40 border-radius-4 text-16 font-weight-500 my-2 px-5 py-3 cursor-pointer"
    >
      {/* <!--begin::Body--> */}
      <div className="d-flex justify-content-between align-content-center">
        <div>
          {/* {eachChoice.choice} */}
          <span>{pageName}</span>
        </div>
        <div>
          <DisplaySvg svgUrl={'/media/svg/ndovu/arrow.svg'} />
        </div>
      </div>
    </div>
  );
};
