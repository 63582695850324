import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';

import { currencyFlags } from '../../../helpers/constants';
import useResponsiveModalStyles from '../../../helpers/modalStyles';

const CurrenciesModal = ({
  open,
  handleSelect,
  handleClose,
  items,
  selectedItem,
  name,
}) => {
  const classes = useResponsiveModalStyles('390px', '90vh');
  const [filteredCountries, setFilteredCountries] = useState();

  useEffect(() => {
    selectedItem &&
      items &&
      items.forEach((item, i) => {
        if (item.currency === selectedItem?.currency) {
          items.splice(i, 1);
          items.unshift(item);
        }
      });
    setFilteredCountries(items);
  }, [items, selectedItem]);

  const handleChange = event => {
    const { value } = event.target;
    const searchResults = items.filter(item =>
      item.currency.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredCountries(searchResults);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalContainer}
    >
      <div className={classes.modal}>
        <input
          onChange={handleChange}
          type="text"
          name="searchItem"
          className="w-100 border-gray-450 px-5 py-3 border-radius-8 text-13 mb-5"
          placeholder="Search"
          autoComplete="off"
        />
        {filteredCountries?.map(item => (
          <div
            className="d-flex justify-content-between mt-1 cursor-pointer"
            onClick={() => handleSelect(item, name)}
            key={item.id}
          >
            <div>
              <span
                className={`flag-icon ${currencyFlags[item?.currency]}`}
              ></span>
              <span
                style={{
                  color:
                    selectedItem?.currency === item.currency ? '#0071CE' : '',
                }}
                className="ml-2"
              >
                {item?.currency}
              </span>
            </div>
            {selectedItem?.currency === item.currency ? (
              <i
                className="fa fa-check"
                style={{ color: '#0071CE', fontSize: '12px' }}
                aria-hidden="true"
              ></i>
            ) : null}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CurrenciesModal;
