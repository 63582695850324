import React from 'react';
import Modal from '@material-ui/core/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

import './index.scss';
import CloseModal from '../../Common/CloseModal';
import useResponsiveModalStyles from '../../../helpers/modalStyles';

const ReusableModal = ({ open, setOpen, text, title }) => {
  const classes = useResponsiveModalStyles('800px');
  const isMediumScreen = useMediaQuery(
    '(min-width: 501px) and (max-width: 848px)',
  );

  const body = (
    <div className={`${classes.modal} py-10 px-md-10 px-5`}>
      {title ? (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 id="simple-modal-title">{title}</h6>
            <CloseModal setOpen={setOpen} />
          </div>
          <div className="divider"></div>
        </div>
      ) : null}

      {text}
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${classes.modalContainer} ${isMediumScreen ? 'mx-7' : ''}`}
    >
      {body}
    </Modal>
  );
};

export default ReusableModal;
