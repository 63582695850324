import React from 'react';
import { DisplaySvg } from './DisplaySvg';

const UnableViewPassword = ({ handleClickEvent, viewPassword, classes }) => {
  return (
    <div onClick={handleClickEvent} className={`${classes}`}>
      <DisplaySvg
        svgUrl={`/media/svg/ndovu/${
          viewPassword ? 'view-password' : 'hide-password'
        }.svg`}
      />
    </div>
  );
};

export default UnableViewPassword;
