import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { paymentMethod } from '../../../helpers/constants';
import { DisplaySvg } from '../../Common/DisplaySvg';

const RecurringPayments = ({
  recurringPaymentData = null,
  onClick = null,
  paymentChannel,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCancelRecurringPayment = () => {
    setShowDropdown(!showDropdown);
    onClick();
  };

  return (
    <>
      <div className="my-4 font-weight-bold d-flex justify-content-between align-items-center text-13">
        <div className="mt-2">Monthly recurring amount</div>

        <div
          className="d-flex justify-center  edit-recurring-payment-wrapper text-blue"
          onClick={toggleDropdown}
        >
          {currencyHelper(
            recurringPaymentData?.amount,
            recurringPaymentData?.currency?.currency,
          )}
          <DisplaySvg
            className="mb-1 ml-5 edit-recurring-payment-icon"
            onClick={() => toggleDropdown()}
            svgUrl={`/media/svg/ndovu/${'edit'}.svg`}
          />
        </div>

        {showDropdown && (
          <div
            className="border-light-blue-545 text-13   edit-recurring-payment-card"
            onClick={handleCancelRecurringPayment}
          >
            <span>
              {paymentChannel === paymentMethod.MPESA
                ? 'Cancel M-PESA Reminder'
                : 'Cancel recurring payment'}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default RecurringPayments;
