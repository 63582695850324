import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { actions as investorProfileActions } from '../../../Profile/_redux/profileRedux';

import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { goalInvestmentWithMobileMoney } from '../../../Goal/_redux/goalActions';
import { fundInvestmentWithMobileMoney } from '../../../Plan/_redux/plansActions';
import { planAccountUpgradeWithMobileMoney } from '../../_redux/investmentAction';
import PhoneNumber from './PhoneNumber';
import {
  Currency,
  InvestmentAction,
  transactionType as TransactionType,
  pocketActionsEnum,
} from '../../../../helpers/constants';
import Back from '../../../Common/BackArrow';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../../helpers/validatePhoneNumber';
import { topupPocket } from '../../../Pocket/_redux/axiosRequest';
import { CommonSuccessModal } from '../../../Common/CommonSuccessModal';
import { pocketContents } from '../../../Pocket/copies';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import SelectOptionsModal from '../../../Common/SelectOptionsModal';
import getCountries from '../../../../helpers/countries';

const PayWithMobileMoney = ({
  setPayWithMobileMoney,
  amount,
  currency,
  pageTitle,
  selectedCountry,
  promoCode,
  discountAmount,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const allCountries = getCountries();
  const profile = useSelector(state => state.profile);
  const { price } = useSelector(state => state.subscription) || {};
  const { investmentAction, investmentType, transactionType, paymentMethod } =
    useSelector(state => state?.investment) || {};
  const reduxPlanInvestmentDtls = useSelector(
    state => state?.plan?.planInvestmentDetails,
  );
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );
  const subscriptionDetails = useSelector(state => state?.subscription);
  const { id: pocketId, currency: pocketCurrency } =
    useSelector(state => state.pocketDetails) || {};

  const [showCountDown, setShowCountDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    false,
  );
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [openCongratulationModal, setOpenCongratulationModal] = useState(false);
  const [openCountyListModal, setOpenCountyListModal] = useState(false);
  const [country, setCountry] = useState(selectedCountry);

  const { investor } = profile || {};
  const { id: subscriptionPlanId, paymentMethod: subscriptionPaymentMethod } =
    subscriptionDetails || {};

  const updateTermsAndCondition = () => {
    const value = JSON.parse(localStorage.getItem('termsAndConditions'));
    if (!Boolean(value)) return;
    setTermsAndConditionsAccepted(Boolean(value));
  };

  useEffect(() => {
    updateTermsAndCondition();
  }, []);

  const initialValues = {
    phoneNumber: investor?.phoneNumber
      ? parsePhoneNumber(investor?.phoneNumber).nationalNumber
      : '',
  };

  const PhoneNumberSchema = Yup.object().shape({
    phoneNumber: Yup.number().required('Phone number is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: PhoneNumberSchema,
    onSubmit: async () => {
      const phoneNumber = formik?.values?.phoneNumber;

      const fullPhoneNumber = getFullPhoneNumber(
        country?.countryCallingCode,
        phoneNumber,
      );

      const isValid = validatePhoneNumber(fullPhoneNumber, phoneNumber);
      setValidPhoneNumber(isValid);

      if (!isValid) return;

      setLoading(true);
      setShowCountDown(true);
      try {
        // SEND PUSH NOTIFICATION ON MOBILE TO APPROVE PAYMENT
        const { GOAL, DIRECT } = TransactionType;
        const { SUBSCRIPTION, POCKET_TOPUP } = InvestmentAction;

        const genericPayload = {
          phoneNumber: fullPhoneNumber,
          promoCode,
        };

        const payload = {
          POCKET_TOPUP: {
            ...genericPayload,
            pocketId: Number(pocketId),
            amount,
            currency: pocketCurrency,
            paymentOption: paymentMethod,
          },
          SUBSCRIPTION: {
            ...genericPayload,
            subscriptionId: Number(subscriptionPlanId),
            amount,
            currency: price?.currency,
            paymentOption: subscriptionPaymentMethod,
          },
          DIRECT: {
            ...genericPayload,
            id: Number(reduxPlanInvestmentDtls.planId),
            type: investmentType,
            amount: convertAmountToNumber(reduxPlanInvestmentDtls?.amount),
            termsAndConditionsAccepted,
            paymentOption: paymentMethod,
            currency: reduxPlanInvestmentDtls?.currency,
          },
          GOAL: {
            ...genericPayload,
            id: Number(reduxGoalInvestmentDtls.goalId),
            type: investmentType,
            amount: convertAmountToNumber(reduxGoalInvestmentDtls?.amount),
            paymentOption: paymentMethod,
            currency: reduxGoalInvestmentDtls?.currency,
          },
        };

        const isPocketTopUp = investmentAction === POCKET_TOPUP;

        const handleRedirection = () => {
          setLoading(false);
          setShowCountDown(false);

          localStorage.removeItem('discountInfo');
          localStorage.removeItem('promoCode');

          if (isPocketTopUp) {
            setOpenCongratulationModal(true);
            return;
          }

          history.push(
            investmentAction === SUBSCRIPTION
              ? '/subscription'
              : '/congratulations',
          );
        };

        if (isPocketTopUp) {
          await topupPocket(payload[POCKET_TOPUP]);

          handleRedirection();
          return;
        }

        if (investmentAction === SUBSCRIPTION) {
          await planAccountUpgradeWithMobileMoney(payload[SUBSCRIPTION]);

          dispatch(
            investorProfileActions.profileAction({
              ...profile,
              investor: {
                ...investor,
                subscription: {
                  ...investor?.subscription,
                  ...subscriptionDetails,
                },
              },
            }),
          );

          handleRedirection();
          return;
        }

        if (transactionType === DIRECT) {
          !termsAndConditionsAccepted &&
            delete payload[DIRECT].termsAndConditionsAccepted;

          await fundInvestmentWithMobileMoney(payload[DIRECT]);

          handleRedirection();
          return;
        }

        if (transactionType === GOAL) {
          await goalInvestmentWithMobileMoney(payload[GOAL]);

          handleRedirection();
          return;
        }
      } catch (error) {
        setShowCountDown(false);
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage:
            error.response?.data?.message || error.response?.data?.errorMessage,
          alertMessageType: 'error',
        });
      }
    },
  });

  const handleSelect = country => {
    setCountry(country);
    setOpenCountyListModal(false);
  };

  const modalContent = (
    <>
      {
        pocketContents({
          inputOne: {
            amount: moneyFormat(amount),
            currency: pocketCurrency,
          },
        })?.[pocketActionsEnum.TOPUP]?.congratulationsModalContent
      }
    </>
  );

  return (
    <>
      {pageTitle ? (
        <Back onClick={() => setPayWithMobileMoney(false)} title={pageTitle} />
      ) : null}
      <PhoneNumber
        setPayWithMobileMoney={setPayWithMobileMoney}
        amount={amount}
        currency={currency}
        validPhoneNumber={validPhoneNumber}
        country={country}
        formik={formik}
        showCountDown={showCountDown}
        loading={loading}
        alertOn={alertOn}
        alert={alert}
        setOpen={
          currency === Currency.EUR && !loading
            ? setOpenCountyListModal
            : () => {}
        }
        discountAmount={discountAmount}
      />

      <CommonSuccessModal
        open={openCongratulationModal}
        setOpen={() => setOpenCongratulationModal(false)}
        content={modalContent}
        moreClasses={'bg-white'}
        handleButtonClick={() => {
          history.push(`/pocket/${pocketId}`);
        }}
      />

      <SelectOptionsModal
        open={openCountyListModal}
        handleClose={() => setOpenCountyListModal(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={country}
      />
    </>
  );
};

export default PayWithMobileMoney;
