import React from 'react';
import Modal from '@material-ui/core/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

import './index.scss';
import CloseModal from '../CloseModal';
import useResponsiveModalStyles from '../../../helpers/modalStyles';

const TextModal = ({ open, setOpen, text, title }) => {
  const classes = useResponsiveModalStyles('800px', '95vh');
  const isMediumScreen = useMediaQuery(
    '(min-width: 501px) and (max-width: 848px)',
  );

  const body = (
    <div className={classes.modal}>
      <div className="d-flex px-8 py-6 justify-content-between align-items-center">
        <div
          className="text-center text-14 font-weight-600"
          id="simple-modal-title"
        >
          {title}
        </div>
        <CloseModal setOpen={setOpen} />
      </div>

      <div className="divider"></div>
      {text}
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${classes.modalContainer} ${isMediumScreen ? 'mx-7' : ''}`}
    >
      {body}
    </Modal>
  );
};

export default TextModal;
