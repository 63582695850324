import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';
import * as auth from '../../Auth/_redux/authRedux';

import getCountries from '../../../helpers/countries';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import Dropdown from '../../Common/Dropdown';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { PhoneNumberInput } from '../../Common/PhoneNumberInput';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import { additionalSignupInformationInitialValues } from '../../Auth/InitialValues';
import { additionalSignupInformation } from '../../Auth/Schemas';
import { addContactInformation } from '../../Auth/_redux/authCrud';
import logout from '../../Auth/pages/Logout';
import { countries } from 'countries-list';
import { parsePhoneNumber } from 'libphonenumber-js';
import { accountTypes } from '../../../helpers/constants';
import Loader from '../../Common/Loader';
import { sendReferralCode } from '../../Account/_redux/referralActions';

const AdditionalInformation = props => {
  const { intl } = props;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingInvestorInfo, setLoadingInvestorInfo] = useState(true);

  const [allCountries, setAllCountries] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [
    phoneNumberWithoutCountryCode,
    setPhoneNumberWithoutCountryCode,
  ] = useState('');
  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);
  const [referralCodeFailed, setReferralCodeFailed] = useState();

  const profile = useSelector(state => state.profile);

  const { investor, user } = profile || {};

  let phoneNumber = investor?.phoneNumber;

  const { country, investorType } = investor || {};

  const { isNdovuModalViewed } = user || {};

  const [selectedCountry, setSelectedCountry] = useState({
    countryCallingCode: phoneNumber
      ? parsePhoneNumber(phoneNumber).countryCallingCode
      : null,
    name: phoneNumber
      ? countries[parsePhoneNumber(phoneNumber).country].name
      : null,
    flag: phoneNumber
      ? `flag-icon-${parsePhoneNumber(phoneNumber).country.toLowerCase()}`
      : null,
    countryCode: phoneNumber ? parsePhoneNumber(phoneNumber).country : null,
    currency: phoneNumber
      ? countries[parsePhoneNumber(phoneNumber).country].name
      : null,
  });

  const [residenceCountry, setResidenceCountry] = useState({
    countryFlag: country ? `flag-icon-${country?.toLowerCase()}` : null,
    currency: countries[country]?.name,
    countryCode: country,
  });

  const dispatch = useDispatch();

  const redirectToHomeOrStayOnThePage = () => {
    if (Object.entries(investor).length > 0) {
      if (
        (!country ||
          (!phoneNumber && investorType === accountTypes.INDIVIDUAL)) &&
        !isNdovuModalViewed
      ) {
        setLoadingInvestorInfo(false);
      } else {
        isNdovuModalViewed && history.push('/home');
        setLoadingInvestorInfo(false);
      }
    }
  };

  useEffect(() => {
    redirectToHomeOrStayOnThePage();
  }, []);

  useEffect(() => {
    const countries = getCountries();
    setAllCountries(countries);
    if (phoneNumber) {
      getDefaultPhoneNumber(countries);
    }
  }, []);

  // separate the country code from the phone
  // search for that country
  // if no country is found with the country code ignore the phone number...
  const getDefaultPhoneNumber = countries => {
    const pNumberWithoutSpecialChars = phoneNumber.replace(/[^0-9]/g, '');
    const countryCode = pNumberWithoutSpecialChars.substring(0, 3);
    const phoneNumberWithoutCountryCode = pNumberWithoutSpecialChars.substring(
      3,
    );
    const countryRegistered = countries.find(
      country => country?.countryCallingCode === countryCode,
    );

    if (countryRegistered) {
      setSelectedCountry(countryRegistered);
      setPhoneNumberWithoutCountryCode(phoneNumberWithoutCountryCode);
    }
  };

  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  if (phoneNumberWithoutCountryCode) {
    phoneNumber = phoneNumberWithoutCountryCode;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: additionalSignupInformationInitialValues(
      investor?.phoneNumber
        ? parsePhoneNumber(investor?.phoneNumber).nationalNumber
        : null,
    ),
    validationSchema: additionalSignupInformation(intl),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const displayError = errorMessage => {
        setSubmitting(false);
        setStatus(errorMessage);
        disableLoading();
      };

      try {
        enableLoading();

        const fullPhoneNumber = getFullPhoneNumber(
          selectedCountry?.countryCallingCode,
          values.phoneNumber,
        );

        const isValid = validatePhoneNumber(
          fullPhoneNumber,
          values.phoneNumber,
        );
        setValidPhoneNumber(isValid);

        let payload = {
          phoneNumber: fullPhoneNumber,
          country: residenceCountry?.countryCode,
        };

        if (isValid) {
          await addContactInformation(payload);

          // google tag manager integration
          TagManager.dataLayer({
            dataLayer: {
              event: 'userFormSubmit',
              user: {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                phoneNumber: payload.phoneNumber,
              },
            },
          });

          dispatch(
            investorProfileActions.profileAction({
              ...profile,
              user: user,
              investor: {
                ...investor,
                phoneNumber: fullPhoneNumber,
                country: residenceCountry?.countryCode,
              },
            }),
          );

          const referralCode = localStorage.getItem('referralCode');

          if (referralCode) {
            try {
              await sendReferralCode(referralCode?.toUpperCase());
              localStorage.removeItem('referralCode');
              disableLoading();
              setSubmitting(false);
            } catch (error) {
              setReferralCodeFailed(true);
              displayError(
                error?.response?.data
                  ? error.response.data.message
                  : 'Oops! Something went wrong with your Code. Please try again or contact support for assistance',
              );
              return;
            }
          }

          disableLoading();
          setSubmitting(false);

          localStorage.removeItem('idToken');
          localStorage.removeItem('wizard');

          disableLoading();
          setSubmitting(false);
          history.push('/onboarding/selectAccountType');
        } else {
          displayError('Use a valid phone number');
        }
      } catch (error) {
        displayError(error?.response?.data?.message);
      }
    },
  });

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpen(false);
  };

  const handleSelectResidenceCountry = country => {
    setResidenceCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    setSelectedCountry(country);
    setOpenCountryDropdown(false);
  };

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  return (
    <>
      {loadingInvestorInfo ? (
        <Loader />
      ) : referralCodeFailed ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="my-5">
            <div className="text-left font-weight-bold text-red-500">
              {formik.status}
            </div>
          </div>

          <ConfirmButton
            buttonType={'button'}
            handleOnClick={() => history.push('/onboarding/selectAccountType')}
            buttonText="Next"
            loading={loading}
            disable={loading}
            classes={'mx-uto'}
          />
        </div>
      ) : (
        <div className="login-form login-signin" style={{ display: 'block' }}>
          <ExternalPageTitle
            title="Investment options for you"
            subTitle="We require the information below to tailor your experience"
          />

          <div className="text-14 text-blue font-weight-500">
            Provide your country of residence and phone number
          </div>

          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework mt-n3"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status ? (
              <div className="my-5">
                <div className="text-left font-weight-bold text-red-500">
                  {formik.status}
                </div>
              </div>
            ) : null}
            {/* end: Alert */}

            <div className="mx-auto input-spacing">
              <Dropdown
                handleOpenSelector={handleCountryDropdown}
                dropdownPlaceholder={'Select country of residence'}
                selectedOption={residenceCountry}
                classes={'mt-7 pr-5 pl-2'}
                name={'residenceCountry'}
              />
            </div>
            {/* begin: Phone number */}

            {residenceCountry?.countryCode ? (
              <PhoneNumberInput
                setOpen={setOpen}
                selectedCountry={selectedCountry}
                formik={formik}
                validPhoneNumber={validPhoneNumber}
              />
            ) : null}
            {/* end: Phone number */}

            <ButtonsContainer justifyContent="justify-content-between">
              {' '}
              <div className="auth-buttons-size">
                <CancelButton
                  buttonText="Cancel"
                  buttonWidth={'w-100'}
                  handleOnClick={logout}
                />
              </div>
              <ConfirmButton
                buttonType={'submit'}
                buttonText="Next"
                loading={loading}
                disable={formik.isSubmitting}
                buttonWidth={'auth-buttons-size'}
              />
            </ButtonsContainer>
          </form>

          <SelectOptionsModal
            open={open}
            handleClose={() => setOpen(false)}
            allCountries={allCountries}
            handleSelect={handleSelect}
            selectedCountry={selectedCountry}
          />

          <SelectOptionsModal
            open={openCountryDropdown}
            handleClose={() => setOpenCountryDropdown(false)}
            allCountries={allCountries}
            handleSelect={handleSelectResidenceCountry}
            placeholder={'Select Country'}
            selectedCountry={residenceCountry}
          />
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(AdditionalInformation));
