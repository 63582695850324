import React from 'react';
import { DisplaySvg } from '../../../../app/modules/Common/DisplaySvg';

export function ScrollTop() {
  return (
    <div id="kt_scrolltop" className="scrolltop">
      <span className="svg-icon">
        <DisplaySvg svgUrl={'/media/svg/icons/Navigation/Up-2.svg'} />
      </span>{' '}
    </div>
  );
}
