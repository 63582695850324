/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { trackEventMixpanel } from './eventTracking/mixpanel';
import { checkToken, isTokenValidated } from './helpers/auth';
import { accountTypes } from './helpers/constants';
import useObserveAndDetectClick from './helpers/CustomHook/useElementObserver';
import { AuthPage, Logout } from './modules/Auth';
import TokenAuth from './modules/Auth/pages/TokenAuth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { Onboarding } from './modules/Onboarding';
import WizardPage from './modules/Wizard/pages/Wizard';
import { OpenInBrowser } from './OpenInBrowser';

export function Routes() {
  const { profile, tokenValidated } = useSelector(
    ({ auth, profile }) => ({
      profile,
      tokenValidated: auth.tokenValid,
    }),
    shallowEqual,
  );

  const { user, investor } = profile || {};
  const { country, phoneNumber, investorType } = investor || {};
  const { twofactorauthenabled, otpCode, isNdovuModalViewed } = user || {};
  const [isInstagramWebview, setIsInstagramWebview] = useState(false);

  const { isTokenValid } = checkToken();

  const handleClick = () => {
    trackEventMixpanel('Help_Button_Click');
  };

  useObserveAndDetectClick('zsiq_float', handleClick);

  useEffect(() => {
    const detectWebview = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Detect WebView (Instagram, Facebook, TikTok, etc.)
      const isWebView = /FBAN|FBAV|Instagram|Twitter|Snapchat|TikTok|WebView/i.test(
        userAgent,
      );

      setIsInstagramWebview(isWebView);
    };

    detectWebview();
  }, []);

  return (
    <>
      {isInstagramWebview ? <OpenInBrowser /> : null}
      <Switch>
        <Route path="/wizard" component={WizardPage} />
        <Route path="/onboarding" component={Onboarding} />
        {!isTokenValid ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isTokenValid ? (
          <Redirect to="/auth/login" />
        ) : !twofactorauthenabled ? (
          (!country ||
            (!phoneNumber && investorType === accountTypes.INDIVIDUAL)) &&
          !isNdovuModalViewed ? (
            <Redirect to="/onboarding/contact-information" />
          ) : (
            <Layout>
              <BasePage />
            </Layout>
          )
        ) : (isTokenValidated() && !otpCode) || tokenValidated ? (
          <Layout>
            <BasePage />
          </Layout>
        ) : (
          <TokenAuth />
        )}
      </Switch>
    </>
  );
}
