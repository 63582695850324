import React from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

import ArrowDownIcon from '../../icons/arrow-down.svg';

export const Accordion = ({
  width,
  text,
  description,
  displayAccordionDescription,
  handleDescriptionDisplay,
  name,
}) => {
  return (
    <div
      className={`${width} mx-auto bg-second-white border-white-50 border-radius-5 px-2 py-3 mt-2`}
      onClick={() => handleDescriptionDisplay(name)}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div>{text}</div>
        <motion.div
          animate={{ rotate: displayAccordionDescription ? 180 : 0 }}
          className={'cursor-pointer'}
          transition={{ duration: 0.3 }}
        >
          <img src={ArrowDownIcon} alt="Select Icon" />
        </motion.div>
      </div>

      <AnimatePresence initial={false}>
        {displayAccordionDescription ? (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={{ overflow: 'hidden' }}
          >
            {description}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
