import React from 'react';
import Modal from '@material-ui/core/Modal';

import CloseModal from '../../Common/CloseModal';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';
import Alert from '../../Common/Alert';
import useResponsiveModalStyles from '../../../helpers/modalStyles';

const KycReasonModal = ({
  openReasonModal,
  setOpenReasonModal,
  setReason,
  sendKYCUpdateRequest,
  loading,
  reason,
  error,
}) => {
  const classes = useResponsiveModalStyles('800px');

  const handleChange = e => {
    setReason(e.target.value);
  };

  const body = (
    <div className={classes.modal}>
      <div className="d-flex justify-content-between align-items-center">
        <div id="simple-modal-title" className="text-20 font-weight-500">
          Reason
        </div>
        <CloseModal setOpen={setOpenReasonModal} />
      </div>
      <div className="divider my-3"></div>
      {error?.alertMessage ? <Alert alert={error} /> : null}
      <p className="my-5" id="simple-modal-description">
        <textarea
          className="reason-text-area"
          name="reason-text-area"
          placeholder="Enter the reason for requesting investor information change"
          onChange={handleChange}
          rows="4"
          cols="50"
        />
      </p>

      <div className="d-flex justify-content-center buttons-gap">
        <CancelButton
          buttonText="CANCEL"
          handleOnClick={() => setOpenReasonModal(false)}
        />

        <ConfirmButton
          buttonText={'CONTINUE'}
          handleOnClick={() => {
            trackEventMixpanel('Submit_Update_KYC_Reason');
            sendKYCUpdateRequest();
          }}
          loading={loading}
          disable={!reason?.length}
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={openReasonModal}
      onClose={() => setOpenReasonModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
    >
      {body}
    </Modal>
  );
};

export default KycReasonModal;
