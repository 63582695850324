import React from 'react';
import SVG from 'react-inlinesvg';
import { referralCodeActions } from '../../../helpers/constants';
import { Copied } from '../../Common/Copied';

export const CopyOrShareCode = ({ iconName, handleClick, action, copied }) => {
  const text = {
    [referralCodeActions.COPY]: 'Copy Code',
    [referralCodeActions.SHARE]: 'Share Via',
  };

  return (
    <div
      className="d-flex flex-column align-items-center cursor-pointer"
      onClick={() => handleClick(action)}
    >
      <div>
        <SVG src={`/media/svg/ndovu/${iconName}.svg`} />
      </div>
      <div className="text-14 font-weight-500 mt-1">{text[action]}</div>
      <Copied copied={copied} />
    </div>
  );
};
