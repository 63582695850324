import React from 'react';

import './KycProgressBar.scss';
import { DisplaySvg } from '../../Common/DisplaySvg';

const KycProgressBar = ({ stepperData }) => {
  return (
    <div className="d-flex justify-content-around align-items-center kyc-progress-bar">
      {stepperData.map(step => (
        <div key={step.id}>
          <div>
            <span className="mr-1 svg-icon svg-icon-md">
              <DisplaySvg
                svgUrl={`/media/svg/ndovu/${
                  step.done ? 'yellow' : 'blue'
                }-check.svg`}
                className="kyc-icon"
              />
            </span>
            <span className="kyc-progress-bar-text">{step.text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KycProgressBar;
