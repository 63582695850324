import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useModalStyles = makeStyles(theme => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  modal: {
    width: props => props.width,
    maxHeight: props => props.height,
    overflow: 'auto',
    backgroundColor: props => props.background,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const useResponsiveModalStyles = (
  customWidth,
  customHeight,
  backgroundColor,
) => {
  const isSmallScreen = useMediaQuery('(max-width: 500px)');

  const width = isSmallScreen ? '90%' : customWidth || '455px';

  const height = customHeight || 'auto';

  const background = backgroundColor || '#ffffff';

  return useModalStyles({ width, height, background });
};

export default useResponsiveModalStyles;
