import React from 'react';
import Modal from '@material-ui/core/Modal';

import { CancelButton, ConfirmButton } from './ReusableButtons';
import { ButtonsContainer } from './ButtonsContainer';
import useResponsiveModalStyles from '../../helpers/modalStyles';
import { DisplaySvg } from './DisplaySvg';

export const ActivateCameraModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  title,
  handleCancelButton,
  iconName,
}) => {
  const classes = useResponsiveModalStyles('455px');

  const body = (
    <div
      className={`${classes.modal} ${moreClasses} border-light-blue-600 border-radius-8 p-10`}
    >
      <div className="d-flex align-items-start">
        <DisplaySvg
          svgUrl={`/media/svg/ndovu/${iconName}.svg`}
          className="mr-5"
        />
        <div>
          <div className="w-100 text-18 text-blue font-weight-600">
            {title ? title : 'Notification'}
          </div>
          <div className="text-10">{content}</div>
        </div>
      </div>

      <ButtonsContainer justifyContent="justify-content-between mt-10">
        <CancelButton
          buttonText="Previous"
          handleOnClick={handleCancelButton}
          twinButton
        />
        <ConfirmButton
          buttonText={'Activate Camera'}
          handleOnClick={handleButtonClick}
          twinButton
        />
      </ButtonsContainer>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
    >
      {body}
    </Modal>
  );
};
