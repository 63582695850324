import React from 'react';

import { FullWidthWrapper } from '../../Common/FullWidthWrapper';
import { DisplaySvg } from '../../Common/DisplaySvg';

export const NoInvitesOrTeamMembers = ({ message }) => {
  return (
    <FullWidthWrapper>
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <DisplaySvg svgUrl={'/media/svg/ndovu/empty.svg'} />
        <span className="text-center text-16 text-blue font-weight-500">
          {message}
        </span>
      </div>
    </FullWidthWrapper>
  );
};
