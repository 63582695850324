import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as investmentReducerActions } from '../modules/Invest/_redux/investmentReducers';

import { CommonSuccessModal } from '../modules/Common/CommonSuccessModal';
import { PermissionModalContent } from '../modules/Common/PermissionModalContent';
import { checkInvestorPermission } from './checInvestorPermission';
import { CustomButton } from '../modules/Common/ReusableButtons';
import { eventTracking } from '../eventTracking/httpRequest';
import {
  customerIOEventType,
  InvestmentAction,
  investmentType,
  transactionType,
} from './constants';
import { trackEventMixpanel } from '../eventTracking/mixpanel';

const PageHeader = ({
  title,
  buttonLabel,
  buttonLink,
  hide,
  investingInGoal,
}) => {
  const { user, investor } = useSelector(state => state.profile);
  const investment = useSelector(state => state.investment);

  let history = useHistory();
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const handleClick = async () => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      'investment',
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    trackEventMixpanel(
      investingInGoal ? 'Add_New_Goal_Click' : 'Invest_In_New_Fund_Click',
    );

    try {
      await eventTracking(
        investingInGoal
          ? customerIOEventType.ADD_NEW_GOAL
          : customerIOEventType.INVEST_ON_HOME_PAGE,
      );
    } catch (error) {}

    if (!investor?.riskProfile && investingInGoal) {
      dispatch(
        investmentReducerActions.updateInvestmentAction({
          ...investment,
          investmentAction: InvestmentAction.GOAL_INVESTMENT,
          transactionType: transactionType.GOAL,
          investmentType: investmentType.INITIAL_INVESTMENT,
        }),
      );

      history.push('/wizard');
      return;
    }

    //TODO: use same localstorage key for back button url
    localStorage.setItem('backLink', history.location.pathname);
    history.push(buttonLink);
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="goal-header-title">{title}</div>
      {hide ? (
        <></>
      ) : (
        <CustomButton
          buttonText={buttonLabel}
          handleOnClick={handleClick}
          buttonIcon={'/media/svg/ndovu/plus-blue.svg'}
          classes={'bg-light-blue-450 text-blue border-radius-4'}
          buttonWidth={'invest-in-new-fund-button'}
        />
      )}

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<PermissionModalContent />}
        moreClasses={'bg-white'}
        title={'Notification'}
        handleButtonClick={() => setShow(false)}
        addCloseButton={true}
      />
    </div>
  );
};

export default PageHeader;
