import React, { useState } from 'react';
import SVG from 'react-inlinesvg';

import { actions as profileActions } from '../../Profile/_redux/profileRedux';

import { handleCopy } from '../../../helpers/copyText';
import { referralPromoTabs } from '../../../helpers/constants';
import { ConfirmButton } from '../../Common/ReusableButtons';
import removeVoucherCorporate from '../_redux/voucherCorporateAction';
import Alert from '../../Common/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { Copied } from '../../Common/Copied';

export const ReferralContentCard = ({
  title,
  referralCode,
  content,
  iconName,
  onEnterReferralCode,
  corporateLogoLink,
  currentTab,
}) => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const [copied, setCopied] = useState(false);
  const initialAlert = {
    alertMessage: null,
    alertMessageType: null,
  };
  const [alert, setAlert] = useState(initialAlert);
  const [loading, setLoading] = useState(false);

  const copyReferralCode = async () => {
    const textCopied = await handleCopy(referralCode);
    setCopied(textCopied);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await removeVoucherCorporate();
      if (response.status === 200) {
        setAlert({
          alertMessage: response.data.message,
          alertMessageType: 'success',
        });

        dispatch(
          profileActions.profileAction({
            ...profile,
            investor: { ...profile.investor, voucherCorporate: null },
          }),
        );

        setLoading(false);
        setTimeout(() => {
          setAlert(initialAlert);
        }, 3000);
      }
    } catch (error) {
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  const corporateCodeSubmitted =
    corporateLogoLink && currentTab === referralPromoTabs.CORPORATE_CODE;

  return (
    <div className="border-gray border-radius-3 d-flex flex-column align-items-center mx-auto px-md-20 px-5 mt-13 py-6">
      {alert?.alertMessage ? <Alert alert={alert} /> : null}
      <div className="text-center">
        {corporateCodeSubmitted ? (
          <img
            src={corporateLogoLink}
            alt="Corporate logo"
            style={{ width: '20%', height: '20%', borderRadius: '5px' }}
          />
        ) : (
          <SVG src={`/media/svg/ndovu/${iconName}.svg`} />
        )}
      </div>
      <div
        style={{ width: '215px' }}
        className="text-16 code-sharing-title mt-5 text-center"
      >
        {title}
      </div>

      {referralCode && !onEnterReferralCode ? (
        <div className="d-flex justify-content-center align-items-center bg-orange-90 referral-code border-radius-31 py-3 mt-5">
          <div className="text-primary-orange font-weight-600">
            {referralCode}
          </div>
          <div
            onClick={copyReferralCode}
            className="ml-2 cursor-pointer referral-code-copy-icon"
          >
            <SVG
              className="w-100 h-100"
              src={`/media/svg/ndovu/copy-orange.svg`}
            />
          </div>
        </div>
      ) : null}

      <Copied copied={copied} />
      <div className="share-code-description text-center">{content}</div>

      {corporateCodeSubmitted ? (
        <div className="mt-5">
          <ConfirmButton
            buttonText={'I left the company'}
            handleOnClick={handleClick}
            changeText={false}
            loading={loading}
          />
        </div>
      ) : null}
    </div>
  );
};
