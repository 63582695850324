const initialState = {
  investmentAction: '',
  paymentMethod: '',
  transactionType: '',
  investmentType: '',
};

export const actionTypes = {
  UpdateInvestmentAction: '[UpdateInvestmentAction] Action',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateInvestmentAction: {
      const investmentDetails = action.payload;
      return { ...state, ...investmentDetails };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  updateInvestmentAction: investmentDetails => ({
    type: actionTypes.UpdateInvestmentAction,
    payload: investmentDetails,
  }),

  resetInvestmentAction: () => ({
    type: actionTypes.UpdateInvestmentAction,
    payload: initialState,
  }),
};
