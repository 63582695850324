import React from 'react';

import { moneyFormat } from '../../../../helpers/moneyFormat';
import { DisplayImage } from '../../../Common/DisplayImage';

export const PendingAmountAndCurrency = ({
  currencyFlag,
  totalPendingAmount,
  currency,
}) => {
  return (
    <div className="height-35 d-flex justify-content-between align-items-center">
      <div
        style={{ width: '81px' }}
        className="d-flex justify-content-center align-items-center bg-gray-430 border-radius-24 py-1"
      >
        <div className="card-flag border-radius-2">
          <DisplayImage src={currencyFlag} alt="country flag" />
        </div>

        <div className="ml-2 text-12">{currency}</div>
      </div>

      {totalPendingAmount > 0 ? (
        <div className="text-orange-400 text-12 font-weight-500 text-right ml-2">
          Pending Amount: {moneyFormat(totalPendingAmount)}
        </div>
      ) : null}
    </div>
  );
};
