import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import '../index.scss';
import PropTypes from 'prop-types';
import Referral from './Referal';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Back from '../../Common/BackArrow';
import { menuNames, referralPromoTabs } from '../../../helpers/constants';
import { InvestmentCredit } from './InvestmentCredit';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const tabsCommonStyles = {
  fontSize: '13px',
  height: '33px',
  minHeight: '33px',
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

  tabsContainer: {
    display: 'flex',
    justifyContent: 'center',
    overflowX: 'auto',
  },

  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },

    '& .MuiTabs-scroller': {
      overflowX: 'auto',
    },
  },

  tab: {
    '&.MuiTab-root': {
      padding: '10px',
      textTransform: 'none',
      color: '#3E3E3E',
      transition: 'background-color 0.3s',
      fontSize: '16px',
      margin: '0 10px',
      borderRadius: '24px',
      width: '180px',

      '&:hover': {
        backgroundColor: '#F1F1F1',
      },

      '&.Mui-selected': {
        backgroundColor: '#F1F1F1',
        color: '#3E3E3E',
        fontWeight: 600,
      },
    },
  },

  tabReferral: {
    '&.MuiTab-root': {
      width: '180px',

      [theme.breakpoints.down('md')]: {
        ...tabsCommonStyles,
        width: '115px',
        margin: '0px',
      },
    },
  },

  tabInvestmentCredit: {
    '&.MuiTab-root': {
      width: '180px',

      [theme.breakpoints.down('md')]: {
        ...tabsCommonStyles,
        width: '142px',
        margin: '0px 8px',
      },
    },
  },

  tabCorporate: {
    '&.MuiTab-root': {
      width: '180px',

      [theme.breakpoints.down('md')]: {
        ...tabsCommonStyles,
        width: '96px',
        margin: '0px',
      },
    },
  },
}));

const ReferralPromo = ({ profile }) => {
  const classes = useStyles();

  const [value, setValue] = useState(() => {
    return Number(localStorage.getItem('selectedTab')) || 0;
  });

  const enterReferralCode = Boolean(localStorage.getItem('enterReferralCode'));
  const [onEnterReferralCode, setOnEnterReferralCode] = useState(
    enterReferralCode,
  );

  const history = useHistory();

  const moveFromEnterReferralCode = () => {
    localStorage.removeItem('enterReferralCode');
    setOnEnterReferralCode(false);
  };

  const handleChange = (event, newValue) => {
    localStorage.setItem('selectedTab', newValue);
    setValue(newValue);

    moveFromEnterReferralCode();
  };

  const previousPage = localStorage.getItem(menuNames.REFERRAL_PROMO);

  const handleBackButton = () => {
    if (enterReferralCode) {
      moveFromEnterReferralCode();
    } else {
      localStorage.removeItem('selectedTab');
      history.push(previousPage);
    }
  };

  return (
    <>
      <Back title="Referral & Promo" onClick={handleBackButton} />
      <div className={classes.root}>
        <div className={classes.tabsContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={`${classes.tabs} mt-5 text-center`}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              className={`${classes.tab} ${classes.tabReferral}`}
              label="Referral Code"
            />

            <Tab
              className={`${classes.tab} ${classes.tabInvestmentCredit}`}
              label={`Investment Credit`}
            />
            <Tab
              className={`${classes.tab} ${classes.tabCorporate}`}
              label="Corporate"
            />
          </Tabs>
        </div>

        {value === 0 && (
          <Referral
            profile={profile}
            setOnEnterReferralCode={setOnEnterReferralCode}
            onEnterReferralCode={onEnterReferralCode}
            currentTab={referralPromoTabs.REFERRAL_PROMO}
            moveFromEnterReferralCode={moveFromEnterReferralCode}
          />
        )}
        {value === 1 && (
          <InvestmentCredit alert={alert} changeTab={handleChange} />
        )}
        {value === 2 && (
          <Referral
            profile={profile}
            currentTab={referralPromoTabs.CORPORATE_CODE}
            changeTab={handleChange}
          />
        )}
      </div>
    </>
  );
};

export default ReferralPromo;
