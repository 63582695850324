import React from 'react';

import { currencyHelper } from '../../../helpers/currencyHelper';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { DisplayImage } from '../../Common/DisplayImage';
import { DisplaySvg } from '../../Common/DisplaySvg';

const PortfolioAssetCard = ({ id: planId, plan, investmentDetails }) => {
  const history = useHistory();
  const { margin, fundPortfolioValue, totalPendingAmount } =
    investmentDetails || {};

  return (
    <div
      className="portfolio-asset-card border-light-blue-540 border-radius-6 cursor-pointer"
      onClick={() => history.push(`/portfolio/viewInvestment/${planId}`)}
    >
      <div className="p-3 w-100">
        {/* Left side of the card  column */}
        <div className="d-flex flex-column">
          {/* First row (Fund name, asset, profit, and pending amount) */}
          <div className="d-flex justify-content-between">
            {/* asset, fund name and profit */}
            <div className="d-flex align-items-center portfolio-card-asset-content">
              {/* asset */}
              <div className="portfolio-asset-image bg-gray-500 overflow-hidden">
                <DisplayImage src={plan.thumbnail} alt={plan.coolName} />
              </div>

              {/* Fund name and profit */}
              <div className="d-flex flex-column ml-3">
                {/* Fund name */}
                <div className="text-15 font-weight-600">{plan?.coolName}</div>

                {/* Profit */}
                <div className="d-flex align-items-center text-13">
                  <div>
                    <span
                      className={`tex-12 ${
                        margin < 0 ? 'text-second-orange' : 'text-second-green'
                      }`}
                    >
                      Profit:
                    </span>{' '}
                    <span
                      className={`${
                        margin < 0 ? 'text-second-orange' : 'text-second-green'
                      } ml-1`}
                    >
                      {margin && margin?.toFixed(2)}%
                    </span>
                  </div>

                  {margin ? (
                    <span>
                      <DisplaySvg
                        svgUrl={`/media/svg/ndovu/polygon-${
                          margin && margin < 0 ? 'red' : 'green'
                        }.svg`}
                      />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Pending amount */}
            {totalPendingAmount ? (
              <div className="text-primary-orange text-right text-13 font-weight-500">
                Pending: {currencyHelper(totalPendingAmount, plan?.currency)}
              </div>
            ) : null}
          </div>

          {/* Second row */}
          {/* Current value*/}
          <div className="current-value ml-auto d-flex justify-content-end">
            <div className="w-100 d-flex justify-content-between mt-4">
              <div className="text-13 text-dark-gray-300">Current Value</div>
              <div className="text-14 font-weight-600 text-right">
                {fundPortfolioValue || fundPortfolioValue === 0
                  ? currencyHelper(fundPortfolioValue, plan?.currency)
                  : '---'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioAssetCard;
