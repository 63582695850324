import React, { useMemo, useState } from 'react';

import {
  fetchDirectInvestmentAssetClasses,
  fetchDirectInvestmentReturnTypes,
} from '../_redux/directInvestmentsActions';
import PortfolioDetailsCard from './PortfolioDetailsCard';

import { pieChatTypes } from '../../../helpers/constants';
import { ShowMoreOrLess } from './ShowMoreOrLess';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';

const PortfolioDetails = ({ assets, currencies }) => {
  const [showMore, setShowMore] = useState(false);
  const [assetClasses, setAssetClasses] = useState([]);
  const [returnTypes, setReturnTypes] = useState([]);
  const [loadingMoreStats, setLoadingMoreStats] = useState(false);

  const toggleShowMore = async () => {
    if (showMore) {
      setShowMore(false);
      return;
    }

    setLoadingMoreStats(true);

    fetchReturnTypes();
    fetchAssetClasses();
  };

  const fetchAssetClasses = async () => {
    // Fetch direct investment asset classes
    !showMore && trackEventMixpanel('Show_Stats_click');
    const response = await fetchDirectInvestmentAssetClasses();
    if (response.data && response.data.data) {
      setAssetClasses(response.data.data);
      setShowMore(!showMore);
      setLoadingMoreStats(false);
    } else {
      setAssetClasses([]);
    }
  };

  const fetchReturnTypes = async () => {
    // Fetch direct investment return types
    const response = await fetchDirectInvestmentReturnTypes();
    if (response.data && response.data.data) {
      setReturnTypes(response.data.data);
    } else {
      setReturnTypes([]);
    }
  };

  const memoizedCards = useMemo(() => {
    return (
      <>
        <PortfolioDetailsCard
          type={pieChatTypes.ASSETS}
          data={assets}
          displayAmount={true}
        />
        <PortfolioDetailsCard
          type={pieChatTypes.CURRENCIES}
          data={currencies}
          displayAmount={true}
        />

        {showMore && (
          <>
            <PortfolioDetailsCard
              type={pieChatTypes.ASSETS_CLASSES}
              data={assetClasses}
            />
            <PortfolioDetailsCard
              type={pieChatTypes.PRODUCT_TYPE}
              data={returnTypes}
            />
          </>
        )}
      </>
    );
  }, [assets, currencies, showMore, assetClasses, returnTypes]);

  return (
    <>
      <div className="portfolio-donut-section">{memoizedCards}</div>
      <ShowMoreOrLess
        toggleShowMoreOrLess={toggleShowMore}
        text={showMore ? 'Less Stats' : 'More Stats'}
        iconUrl={
          showMore
            ? '/media/svg/ndovu/arrow-up.svg'
            : '/media/svg/ndovu/arrow-down.svg'
        }
        loading={loadingMoreStats}
      />
    </>
  );
};

export default PortfolioDetails;
