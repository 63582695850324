import React, { useRef, useState } from 'react';
import { ConfirmButton } from './modules/Common/ReusableButtons';

export const OpenInBrowser = () => {
  const [copied, setCopied] = useState(false);
  const urlInputRef = useRef(null);

  const currentUrl = window.location.href;

  const copyToClipboard = () => {
    if (!urlInputRef.current) return;

    urlInputRef.current.select();
    document.execCommand('copy');
    setCopied(true);

    setTimeout(() => setCopied(false), 3000);
  };
  return (
    <div
      style={{
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        zIndex: 9999,
      }}
      className="w-100 h-100 position-fixed d-flex justify-content-center align-items-center flex-column text-white px-3 text-center"
    >
      <div className="font-weight-500 mb-5 text-18">
        This page works best in your preferred browser.
      </div>

      <div className="font-weight-500 mt-3 text-16">Why?</div>

      <div
        style={{
          maxWidth: '280px',
        }}
        className="mt-2 text-16"
      >
        In-app browser has limitations that may affect your experience.
      </div>

      <div className="font-weight-500 mt-5 text-16">What to do:</div>

      <div className="text-12 mt-4 mb-7 text-left">
        1. Tap <span className="font-weight-600">Copy Link</span> below.
        <br />
        2. Open your preferred browser (Chrome, Safari, etc.).
        <br />
        3. Paste the link in the address bar and press enter.
      </div>

      <div
        style={{
          maxWidth: '280px',
        }}
        className="mb-5 position-relative w-100"
      >
        <input
          ref={urlInputRef}
          readOnly
          value={currentUrl}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className="bg-black-655 p-4 w-100 border-radius-8 border-0 text-14 text-white"
        />
      </div>

      <ConfirmButton
        buttonText={copied ? 'Copied!' : 'Copy Link'}
        handleOnClick={copyToClipboard}
      />
    </div>
  );
};
