import dotenv from 'dotenv';
import mixpanel from 'mixpanel-browser';
import { environment, platformType } from '../helpers/constants';

dotenv.config();

const projectToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
const applicationEnvironment = process.env.NODE_ENV;

// mixpanel.init(projectToken, {
//   persistence: 'localStorage',
//   ...(applicationEnvironment !== environment.PRODUCTION, { debug: true }),
// });

export const trackEventMixpanel = eventName => {
  if (!eventName || !eventName?.length) {
    console.log('Event name is missing');
    return;
  }

  try {
    mixpanel.track(`${eventName}_${platformType.WEB_APP}`, {
      env: process.env.REACT_APP_ENV,
    });
  } catch (error) {
    // console.log('Mixpanel Error', error);
  }
};
