import React from 'react';

const MessageAlert = ({ closeAlert, alert }) => {
  return (
    <div
      className={`alert alert-custom ${
        alert.alertMessageType ? alert.alertMessageType : 'alert-light-danger'
      } fade show mb-5`}
      role="alert"
    >
      <div className="alert-icon">
        <span className="svg-icon svg-icon-3x svg-icon-danger"></span>
      </div>
      <div className="alert-text font-weight-bold">{alert.alertMessage}</div>
      <div className="alert-close">
        <button
          onClick={closeAlert}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="ki ki-close"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default MessageAlert;
