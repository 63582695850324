import jwtDecode from 'jwt-decode';

export const validateToken = () => {
  const authToken = localStorage.getItem('ndovuToken');

  if (!authToken) {
    return;
  }

  const decodedToken = jwtDecode(authToken);

  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem('ndovuToken');
    return;
  } else {
    return authToken;
  }
};
