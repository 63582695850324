import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import { HeaderSection } from './HeaderSection';
import { TransactionBlock } from './TransactionBlock';

export const Timeline = ({ transactionsTimeline }) => {
  const [displayTimeline, setDisplayTimeline] = useState(false);

  const toggleTimeline = () => setDisplayTimeline(!displayTimeline);

  return (
    <div style={{ maxWidth: '550px' }}>
      <HeaderSection
        onClick={toggleTimeline}
        displayTimeline={displayTimeline}
        transactionsNumber={transactionsTimeline?.length}
      />

      <AnimatePresence initial={false}>
        {displayTimeline ? (
          <motion.div
            className="mt-5 transactions-timeline-container"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={{ overflow: 'hidden' }}
          >
            {transactionsTimeline.map(timeline => (
              <TransactionBlock key={timeline.id} timeline={timeline} />
            ))}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
