/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import dotenv from 'dotenv';

import '../index.scss';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { ReferralContentCard } from './ReferralContentCard';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { CopyOrShareCode } from './CopyOrShareCode';
import {
  referralCodeActions,
  referralPromoTabs,
} from '../../../helpers/constants';
import { handleCopy } from '../../../helpers/copyText';
import { ShareModal } from '../../Common/ShareModal';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import { sendReferralCode } from '../_redux/referralActions';
import { getCopies } from '../helpers/getCopies';
import { Accordion } from '../../Common/Accordion';
import { HowReferralWorks, WhyReferFriends } from './AccordionDescription';
import { getBaseUrl } from '../../../helpers/getBaseUrl';

dotenv.config();

const Referral = ({
  profile,
  setOnEnterReferralCode,
  onEnterReferralCode,
  currentTab,
  removeCorporateVoucher,
  alert,
  loading,
  moveFromEnterReferralCode,
  changeTab,
}) => {
  const { userReferralCode, isReferralCompleted, voucherCorporate } =
    profile?.investor || {};
  const baseUrl = getBaseUrl();
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [
    displayAccordionDescription,
    setDisplayAccordionDescription,
  ] = useState({ whyRefer: false, howItWorks: false });

  const isReferralPromoTab = currentTab === referralPromoTabs.REFERRAL_PROMO;

  const shareData = {
    text:
      "Hey! I'm using Ndovu to invest and grow my money. If you sign up using my link, you'll get access to great investment tools, and we both earn $20! Use code NEW50. Here's the link to join:",
    url: `${baseUrl}/auth/select-sign-up-option/${userReferralCode}`,
  };

  const copyReferralCode = async () => {
    const textCopied = await handleCopy(userReferralCode);
    setCopied(textCopied);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const shareReferralCode = async () => {
    setOpenShareModal(true);
  };

  const tabCopies = getCopies(
    onEnterReferralCode,
    voucherCorporate?.corporateName,
  );

  const initialValues = {
    [tabCopies[currentTab].sectionOne.inputName]: '',
  };

  const referralCodeSchema = intl => {
    return Yup.object().shape({
      [tabCopies[currentTab].sectionOne.inputName]: Yup.string()
        .min(8, 'Minimum 8 characters')
        .max(8, 'Maximum 8 characters')
        .required('Required field'),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: referralCodeSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMessage(null);
      setSubmitting(true);
      const referralOrVoucherCodeUpperCase = values[
        tabCopies[currentTab].sectionOne.inputName
      ].toUpperCase();

      try {
        const response = isReferralPromoTab
          ? await sendReferralCode(referralOrVoucherCodeUpperCase)
          : await sendReferralCode(null, referralOrVoucherCodeUpperCase);

        if (isReferralPromoTab) {
          setSuccessMessage(
            'Referral Code applied successfully! Collect your reward after you complete your first investment.',
          );

          setTimeout(() => {
            dispatch(
              investorProfileActions.profileAction({
                ...profile,
                investor: { ...profile?.investor, isReferralCompleted: true },
              }),
            );

            moveFromEnterReferralCode();
          }, 3000);
        } else {
          setSuccessMessage('Corporate code submitted successfully');

          dispatch(
            investorProfileActions.profileAction({
              ...profile,
              investor: {
                ...profile?.investor,
                voucherCorporate: response?.data?.data?.voucherCorporate,
              },
            }),
          );

          changeTab(null, 2);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        setErrorMessage(
          error?.response?.data?.message || 'Something went wrong, Try again.',
        );
      }
    },
  });

  const handleDescriptionDisplay = accordionName => {
    if (accordionName === 'howItWorks') {
      setDisplayAccordionDescription({
        whyRefer: false,
        [accordionName]: !displayAccordionDescription[accordionName],
      });
    } else {
      setDisplayAccordionDescription({
        howItWorks: false,
        [accordionName]: !displayAccordionDescription[accordionName],
      });
    }
  };

  return (
    <div className="referral-content-container mx-auto">
      <ReferralContentCard
        title={tabCopies[currentTab].card.title}
        referralCode={isReferralPromoTab ? userReferralCode : null}
        iconName={tabCopies[currentTab].card.iconName}
        content={tabCopies[currentTab].card.content}
        onEnterReferralCode={onEnterReferralCode}
        corporateLogoLink={voucherCorporate?.logoLink}
        currentTab={currentTab}
        removeCorporateVoucher={removeCorporateVoucher}
        alert={alert}
        loading={loading}
      ></ReferralContentCard>

      {(onEnterReferralCode && !isReferralCompleted) ||
      (currentTab === referralPromoTabs.CORPORATE_CODE &&
        !voucherCorporate?.corporateName) ? (
        <div className="mt-8  mx-auto">
          <div className="text-18 font-weight-600">
            {tabCopies[currentTab].sectionOne.title}
          </div>

          <div className="text-14 mt-2 mb-3">
            {tabCopies[currentTab].sectionOne.subTitle}
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex justify-content-between border-gray-450 align-items-center border-radius-8 px-5">
              <input
                type={'text'}
                className={`w-100 height-45 border-gray-450 pr-3 border-0 text-16 font-weight-500`}
                placeholder={tabCopies[currentTab].sectionOne.inputPlaceholder}
                name={tabCopies[currentTab].sectionOne.inputName}
                {...formik.getFieldProps(
                  tabCopies[currentTab].sectionOne.inputName,
                )}
                autoComplete="off"
                formik={formik}
                onFocus={() => setErrorMessage(null)}
              />

              {formik.isSubmitting ? (
                <span className="spinner spinner-blue px-4" />
              ) : (
                <button
                  type="submit"
                  className={`${
                    isReferralCompleted &&
                    currentTab !== referralPromoTabs.CORPORATE_CODE
                      ? 'text-dark-gray-300'
                      : 'text-blue'
                  } text-16 font-weight-600 border-0 bg-white border-radius-2 px-4`}
                  disabled={
                    isReferralCompleted &&
                    currentTab !== referralPromoTabs.CORPORATE_CODE
                  }
                >
                  Apply
                </button>
              )}
            </div>

            {successMessage ? (
              <div className="text-second-green text-14 mt-3">
                {successMessage}
              </div>
            ) : formik.errors[tabCopies[currentTab].sectionOne.inputName] ||
              errorMessage ? (
              <ValidationErrors
                errorMessage={
                  formik.errors[tabCopies[currentTab].sectionOne.inputName] ||
                  errorMessage
                }
              />
            ) : null}
          </form>
        </div>
      ) : isReferralPromoTab ? (
        <>
          <div className="d-flex flex-column align-items-center mt-10">
            <div className="code-sharing-title text-16 text-center">
              How to share
            </div>

            <div className="d-flex mt-3 gap-40">
              <CopyOrShareCode
                action={referralCodeActions.COPY}
                iconName={'copy-button-blue'}
                copied={copied}
                handleClick={copyReferralCode}
              />

              <CopyOrShareCode
                action={referralCodeActions.SHARE}
                iconName={'share-button-blue'}
                handleClick={shareReferralCode}
              />
            </div>
          </div>

          {!isReferralCompleted ? (
            <div className="mt-3 d-flex flex-column align-items-center">
              <div className="mb-5 text-center text-14 code-sharing-title">
                Have you been referred by your friend?
              </div>
              <ConfirmButton
                buttonText={'Enter Referral Code'}
                handleOnClick={() => {
                  setOnEnterReferralCode(true);
                  localStorage.setItem('enterReferralCode', true);
                }}
                buttonWidth={'width-342'}
              />
            </div>
          ) : null}

          <div className="mt-10">
            <Accordion
              width={'width-342'}
              text={'How referral works'}
              description={<HowReferralWorks />}
              displayAccordionDescription={
                displayAccordionDescription.howItWorks
              }
              name={'howItWorks'}
              handleDescriptionDisplay={handleDescriptionDisplay}
            />
            <Accordion
              width={'width-342'}
              text={'Why Refer Friends?'}
              description={<WhyReferFriends />}
              displayAccordionDescription={displayAccordionDescription.whyRefer}
              name="whyRefer"
              handleDescriptionDisplay={handleDescriptionDisplay}
            />
          </div>
        </>
      ) : null}

      <ShareModal
        setOpen={setOpenShareModal}
        open={openShareModal}
        shareData={shareData}
      />
    </div>
  );
};

export default Referral;
