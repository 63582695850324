import React from 'react';

export const ReferralStatusTab = ({
  label,
  status,
  changeStatus,
  selectedStatus,
}) => {
  return (
    <div
      className={`${
        selectedStatus === status ? 'text-blue' : ''
      } text-14 font-weight-500 cursor-pointer referee-card`}
      onClick={() => changeStatus(status)}
    >
      {label}
    </div>
  );
};
