import { device, operatingSystem } from './constants';

const userAgent = navigator.userAgent.toLowerCase();

export const checkUserDevice = () => {
  if (
    /iphone|ipod|ipad|android|blackberry|mobile|opera mini|windows phone/i.test(
      userAgent,
    )
  ) {
    return device.MOBILE;
  }
};

export const checkUserOS = () => {
  if (/macintosh|mac os x/i.test(userAgent)) {
    return operatingSystem.MACOS;
  }
};
