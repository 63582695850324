import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import SVG from 'react-inlinesvg';

import { moneyFormat } from '../../../../helpers/moneyFormat';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const PortfolioValue = ({
  handleClick,
  portfolioValue,
  isProfitLessThanZero,
  profitAmount,
  profitPercentage,
}) => {
  return (
    <div
      onClick={() => handleClick(`/portfolio`, `Open_Profile`)}
      className="mt-5 cursor-pointer"
    >
      <div className="portfolio-value-label">Portfolio Value</div>
      <motion.div
        className="portfolio-value text-black-650 font-weight-500"
        whileHover={{ scale: 1.05 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        {moneyFormat(portfolioValue)}
      </motion.div>

      <div
        className={`${
          isProfitLessThanZero ? 'text-second-orange' : 'text-green-800'
        } text-16 mt-n2`}
      >
        <div className="d-flex align-items-center">
          <div>
            {` Profit: ${moneyFormat(profitAmount)} ${
              profitPercentage || profitPercentage === 0
                ? `(${profitPercentage})%`
                : ''
            }`}
          </div>
          <div className="ml-2">
            <SVG
              src={toAbsoluteUrl(
                `/media/svg/ndovu/dashboard/${
                  isProfitLessThanZero ? 'loss' : 'profit'
                }.svg`,
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
