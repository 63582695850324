import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';

import { AnimatedPageContainer } from '../AnimatedPageContainer';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import useResponsiveModalStyles from '../../../helpers/modalStyles';
import { handleCopy } from '../../../helpers/copyText';
import { Copied } from '../Copied';
import { checkUserDevice, checkUserOS } from '../../../helpers/checkUserDevice';
import { device, operatingSystem } from '../../../helpers/constants';

export const ShareModal = ({ setOpen, open, shareData }) => {
  const classes = useResponsiveModalStyles('455px', null, '#f1f1f1');
  const { url, text } = shareData;

  const isDesktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const encodedText = encodeURIComponent(text);
  const encodedUrl = encodeURIComponent(url);
  const [copied, setCopied] = useState(false);

  const whatsappUrl = isDesktop
    ? `https://web.whatsapp.com/send?text=${encodeURIComponent(
        `${text} ${url}`,
      )}`
    : `whatsapp://send?text=${encodeURIComponent(`${text} ${url}`)}`;

  const userDevice = checkUserDevice();
  const userOS = checkUserOS();

  const isOnMobileOrMacOS =
    userDevice === device.MOBILE || userOS === operatingSystem.MACOS;

  const facebook = {
    name: 'Facebook',
    url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    iconName: 'facebook',
  };

  const messenger = {
    name: 'Facebook',
    url: `fb-messenger://share/app_id=1150031833260814?link=${encodedUrl}`,
    iconName: 'messenger',
  };

  const forMobileAndMacOS = {
    name: 'Message',
    url: `sms:?&body=${encodedText}%20${encodedUrl}`,
    iconName: 'message',
  };

  const shareOptions = [
    {
      name: 'Mail',
      url: `mailto:?subject=Check%20this%20out!&body=${encodedText}%20${encodedUrl}`,
      iconName: 'mail',
    },
    {
      name: 'Whatsapp',
      iconName: 'whatsapp',
      url: whatsappUrl,
    },
    {
      name: 'X',
      url: `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`,
      iconName: 'twitter',
    },
  ];

  shareOptions.unshift(userDevice === device.MOBILE ? messenger : facebook);
  isOnMobileOrMacOS && shareOptions.unshift(forMobileAndMacOS);

  const handleCopyLink = () => {
    const copiedSuccessfully = handleCopy(url);

    if (copiedSuccessfully) {
      setCopied(copiedSuccessfully);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  const body = (
    <AnimatedPageContainer>
      <div className={`${classes.modal} border-radius-8 pt-5 pb-8 px-10`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex align-items-center">
              <div>
                <SVG src={toAbsoluteUrl(`/media/svg/ndovu/ndovu-logo.svg`)} />
              </div>

              <div className="d-flex flex-column ml-2">
                <div className="text-15 font-weight-600">Ndovu</div>
                <div className="text-13 text-dark-gray-300">ndovu.com</div>
              </div>
            </div>
          </div>
          <div className="cursor-pointer" onClick={() => setOpen(false)}>
            <div className="">
              <SVG src={toAbsoluteUrl(`/media/svg/ndovu/close-icon.svg`)} />
            </div>
          </div>
        </div>

        <div className="border-b-white-450 mb-3 mt-4"></div>
        <div
          className="d-flex gap-25 hidden-scrollbar py-2"
          style={{ maxWidth: '100vw' }}
        >
          {shareOptions.map(shareOption => (
            <div
              key={shareOption.name}
              className="d-flex flex-column justify-content-between align-items-center cursor-pointer"
              onClick={() => window.open(shareOption.url, '_blank')}
            >
              <div>
                <SVG
                  src={toAbsoluteUrl(
                    `/media/svg/ndovu/${shareOption.iconName}.svg`,
                  )}
                />
              </div>
              <div className="mt-n2">{shareOption.name}</div>
            </div>
          ))}
        </div>

        <div className="mb-20">
          <div className="d-flex justify-content-between align-items-center p-4 bg-white box-shadow-black-550 border-radius-9 mt-8">
            <div className="text-10">{url}</div>
            <div className="cursor-pointer ml-2" onClick={handleCopyLink}>
              <SVG src={toAbsoluteUrl(`/media/svg/ndovu/copy-black.svg`)} />
            </div>
          </div>

          <Copied copied={copied} />
        </div>
      </div>
    </AnimatedPageContainer>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${classes.modalContainer} w-100`}
    >
      {body}
    </Modal>
  );
};
