import React from 'react';
import SVG from 'react-inlinesvg';
import { motion } from 'framer-motion/dist/framer-motion';

export const HeaderSection = ({
  onClick,
  displayTimeline,
  transactionsNumber,
}) => (
  <motion.div
    className={`d-flex justify-content-between align-items-center mt-5 border-radius-4 py-3`}
    animate={{
      border: displayTimeline ? '1px solid transparent' : '1px solid #d0dfe6',
      paddingLeft: displayTimeline ? '0px' : '15px',
      paddingRight: displayTimeline ? '0px' : '15px',
    }}
    transition={{ duration: 0.5, ease: 'easeInOut' }}
  >
    <div className="text-16 font-weight-600">{`Pending Transactions (${transactionsNumber})`}</div>
    <motion.div
      animate={{ rotate: displayTimeline ? 180 : 0 }}
      transition={{ duration: 0.3 }}
      className="cursor-pointer"
      onClick={onClick}
    >
      <SVG src={`/media/svg/ndovu/${'arrow-down-grey'}.svg`} />
    </motion.div>
  </motion.div>
);
