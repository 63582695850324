import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';

import { CustomButton } from '../ReusableButtons';
import { ButtonsContainer } from '../ButtonsContainer';
import useResponsiveModalStyles from '../../../helpers/modalStyles';
import { DisplaySvg } from '../DisplaySvg';

const ErrorModal = ({
  open,
  setOpen,
  content,
  modalHeight,
  backgroundColor,
  btnLink,
  clearState,
  showCloseIcon,
  buttonText,
  modalTitle,
  generateModalButtonRedirectLink,
}) => {
  const classes = useResponsiveModalStyles('455px');

  const history = useHistory();

  const body = (
    <div
      className={`${classes.modal} ${modalHeight} ${backgroundColor} border-radius-8 border-1-orange-100 pt-5 pb-8 px-10`}
    >
      {showCloseIcon ? (
        <div
          onClick={() => setOpen(false)}
          className="bg-red-600 text-white border-radius-3 text-15 font-weight-500 ml-auto cursor-pointer"
          style={{
            paddingBottom: '1px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          x
        </div>
      ) : null}
      <div className="d-flex justify-content-start align-items-center flex-column">
        <DisplaySvg
          svgUrl={`/media/svg/ndovu/error.svg`}
          width={'72px'}
          height={'72px'}
          className="mb-10"
        />
        <div className="w-100 modal-summary-header text-center text-red-600 font-weight-600">
          {modalTitle ? modalTitle : 'Error'}
        </div>
      </div>

      <div className="modal-summary-body text-center">{content}</div>

      {
        <ButtonsContainer>
          <CustomButton
            buttonText={buttonText ? buttonText : 'Try again'}
            handleOnClick={() => {
              clearState && clearState();
              setOpen(false);
              (btnLink || generateModalButtonRedirectLink) &&
                history.push(
                  btnLink ? btnLink : generateModalButtonRedirectLink(),
                );
            }}
            classes={'bg-red-600 text-white'}
          />
        </ButtonsContainer>
      }
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
    >
      {body}
    </Modal>
  );
};

export default ErrorModal;
