import React from 'react';

export const getCopies = (onEnterReferralCode, corporateName) => {
  return {
    REFERRAL_PROMO: {
      card: {
        title: onEnterReferralCode
          ? 'Enter a referral code & earn upto KES 1000'
          : 'Share your code and earn upto KES 1000',
        iconName: onEnterReferralCode ? null : 'gift',
        content: onEnterReferralCode
          ? 'Get Investment Credits once you enter a referral code from a friend and invests from KES 5000 for both of you to earn. This is a one time code'
          : 'Get Investment Credits for every friend who registers and invests at least KES 5000 for their first time investment using your referral code or enter a referral code for both of you to earn',
      },
      sectionOne: {
        title: 'Referral Code',
        subTitle: 'Have you been referred to Ndovu? Enter code below',
        inputPlaceholder: 'Enter Code',
        inputName: 'referralCode',
      },
    },
    CORPORATE_CODE: {
      card: {
        title: 'Corporate Promo',
        iconName: 'gift',
        content: (
          <>
            {corporateName ? (
              <div>
                Your account is active with the Promotion for{' '}
                <span className="font-weight-600">{corporateName}</span>
              </div>
            ) : (
              <>
                <div>
                  We work with employers to provide their employees with
                  discount when it comes to building wealth. If you have a
                  corporate code please insert it here to unlock your discount.
                </div>
                <div>
                  <span>
                    If you would like to partner with us, email us at{' '}
                  </span>
                  <span className="font-weight-600">support@ndovu.co</span>
                </div>
              </>
            )}
          </>
        ),
      },
      sectionOne: {
        title: 'Corporate Code',
        subTitle: 'Has your employer given you a code? Enter it below',
        inputPlaceholder: 'Enter Code',
        inputName: 'voucher',
      },
    },
  };
};
