// AccountCards.jsx
import React from 'react';
import radioBtnSelected from '../../../images/radioBtnSelected.svg';
import disabledRadioButton from '../../../images/disabledRadioButton.svg';
import radioBtn from '../../../images/radioBtn.svg';
import './styles.scss';

import {
  serviceProvidersLabel,
  settlementDetailsType,
} from '../../../helpers/constants';

const AccountCards = ({
  settlementDetails,
  onPress,
  selected,
  disable,
  errorMessage,
}) => {
  const {
    bankName,
    serviceProvider,
    currency,
    id: uniqueId,
    phoneNumber,
    accountNumber: currentAccountNumber,
    type,
  } = settlementDetails || {};

  const isMobileMoneySettlementDetails =
    type === settlementDetailsType.MOBILE_MONEY;

  const accountName = isMobileMoneySettlementDetails
    ? serviceProvidersLabel[serviceProvider]
    : bankName;

  const accountType = isMobileMoneySettlementDetails ? 'Mobile' : 'Account';
  const accountNumber = isMobileMoneySettlementDetails
    ? phoneNumber
    : currentAccountNumber;

  return (
    <div className={disable ? 'disabled-account-cards' : 'account-cards'}>
      <div className="card-left">
        <div
          className={`${!disable ? 'cursor-pointer' : ''} icon`}
          onClick={() => !disable && onPress(accountNumber)}
        >
          {selected === uniqueId ? (
            <img src={radioBtnSelected} alt="Radio button" />
          ) : disable ? (
            <img src={disabledRadioButton} alt="Disabled radio button" />
          ) : (
            <img src={radioBtn} alt="Selected radio button" />
          )}
        </div>
      </div>
      {disable ? (
        <div className="card-right">
          <span className="account-title">{accountName}</span>
          <div className="account-details">
            <div>
              {' '}
              {accountType}: {accountNumber}
            </div>
            {errorMessage ? (
              <div className="text-second-orange">{errorMessage}</div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="card-right">
          <span className="account-title">{accountName}</span>
          <div className="account-details">
            <div>
              {' '}
              {accountType}: {accountNumber}
            </div>
            <div>Currency: {currency?.currency}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountCards;
