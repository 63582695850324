import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import RecurringPayment from './pages/RecurringPayment';
import KycProgressBar from '../BusinessChama/helpers/KycProgressBar';
import PaymentDay from './pages/PaymentDay';
import Confirmation from './pages/Confirmation';
import PaymentInformation from './pages/PaymentInformation';
import { useSelector } from 'react-redux';
import { paymentMethod } from '../../helpers/constants';
import BankRecurringPaymentConfirmation from './pages/BankConfirmation';
import { AnimatedPageContainer } from '../Common/AnimatedPageContainer';

const RecurringPayments = ({ match }) => {
  const [stepperData, setStepperData] = useState([
    { id: 1, text: 'Recurring Payment details', done: false },
    { id: 2, text: 'Payment day', done: false },
    { id: 3, text: 'Payment details', done: false },
    { id: 4, text: 'Confirm', done: false },
  ]);

  const [bankStepperData, setBankStepperData] = useState([
    { id: 1, text: 'Recurring Payment details', done: false },
    { id: 2, text: 'Confirm', done: false },
  ]);
  const recurringPaymentData = useSelector(state => state.recurringPayments);

  const handleStepChange = id => {
    const updateStep = data => {
      return data.map(step => {
        let doneValue;

        if (id?.next) {
          doneValue = true;
        }

        return step.id <= id.next
          ? { ...step, done: doneValue }
          : step.id <= id?.previous
          ? { ...step, done: false }
          : step;
      });
    };

    // check the paymentChannel selected and update the appropriate stepper data

    if (recurringPaymentData?.paymentChannel === paymentMethod.BANK_TRANSFER) {
      return setBankStepperData(updateStep(bankStepperData));
    } else {
      return setStepperData(updateStep(stepperData));
    }
  };

  return (
    <AnimatedPageContainer>
      <KycProgressBar
        stepperData={
          recurringPaymentData?.paymentChannel === paymentMethod.BANK_TRANSFER
            ? bankStepperData
            : stepperData
        }
      />
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={props => (
            <RecurringPayment
              {...props}
              id={1}
              handleStepChange={handleStepChange}
            />
          )}
        />

        {/*  for bank recurring payments  */}

        <Route
          exact
          path={`${match.path}/bank-confirmation`}
          render={props => (
            <BankRecurringPaymentConfirmation
              handleStepChange={handleStepChange}
              id={2}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/payments`}
          render={props => (
            <PaymentDay handleStepChange={handleStepChange} id={2} {...props} />
          )}
        />

        <Route
          exact
          path={`${match.path}/bank-details`}
          render={props => (
            <PaymentInformation
              handleStepChange={handleStepChange}
              id={3}
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/confirm`}
          render={props => (
            <Confirmation
              id={4}
              handleStepChange={handleStepChange}
              {...props}
            />
          )}
        />
      </Switch>
    </AnimatedPageContainer>
  );
};

export default RecurringPayments;
