import React from 'react';
import moment from 'moment/moment';
import { moneyFormat } from '../../../helpers/moneyFormat';
import {
  capitalizeTheFirstLetterOfEachWord,
  firstLetterUpperCase,
} from '../../../helpers/investmentHelpers';
import { pocketContents } from '../copies';
import { pocketOperations, transactionType } from '../../../helpers/constants';
import HorizontalLine from '../../../helpers/horizontalLine';

export const SingleTransaction = ({ type, data }) => {
  const walletOperation = type?.replace(/[_/]/g, '')?.toLowerCase();
  const iconName = pocketContents()?.[walletOperation]?.transactionIcon;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <div
            style={{ background: '#E6E6E6' }}
            className="transaction-type border-radius-5 d-flex justify-content-center align-items-center"
          >
            {iconName ? (
              <img
                src={require(`../../../icons/PocketTransactionTypes/${iconName}.svg`)}
                alt={type}
              />
            ) : null}
          </div>
          <div className="d-flex flex-column align-items-start ml-md-5 ml-2">
            <span className="font-weight-500 text-13">
              {data?.plan ? data?.plan?.coolName : 'Ndovu Pocket'}
            </span>
            <div className="d-flex flex-wrap align-items-center text-11">
              <span className="text-blue font-weight-500">
                {`${
                  data?.transaction?.transactionType === transactionType.GOAL
                    ? 'Goal'
                    : ''
                } ${firstLetterUpperCase(type?.replace(/_/g, ''))}`}
              </span>
              <span className="ml-1">
                {moment(data?.createdAt).format('DD-MM-YYYY')}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-end align-items-end">
          <div
            className={`${
              data.operation === pocketOperations.CREDIT
                ? 'text-green'
                : 'text-second-orange'
            } font-weight-500 text-13`}
          >
            {data.operation === pocketOperations.CREDIT ? '+' : '-'}{' '}
            {data?.currency} {moneyFormat(data?.originalAmount?.toFixed(2))}
          </div>
          <div
            className={`transaction_status text-center font-weight-400  py-1`}
          >
            {capitalizeTheFirstLetterOfEachWord(
              data?.status?.replace(/_/g, ' '),
            )}
          </div>
        </div>
      </div>
      <HorizontalLine color="#EFEFEF" width={'100%'} height={0.1} />
    </>
  );
};
