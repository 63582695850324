import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Currency } from '../../../helpers/constants';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import * as recurringPaymentDataState from '../_redux/recurringPaymentRedux';
import MobileMoney from '../components/MobileMoney';
import { getCountryDetailsPerCurrency } from '../../../helpers/getCountryDetails';

const PaymentInformation = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleStepChange, id: stepperId } = props;
  const recurringPaymentData = useSelector(state => state.recurringPayments);
  const [phoneNumber, setPhoneNumber] = useState(
    recurringPaymentData?.phoneNumber
      ? recurringPaymentData?.phoneNumber?.slice(4)
      : '',
  );
  const [mobileValidationErros, setMobileValidationErrors] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    currency: { label: 'KES - Kenyan Shilling', value: Currency.KES },
    bankName: recurringPaymentData?.bankName
      ? { name: recurringPaymentData?.bankName, bankId: '' }
      : { name: '', bankId: '' },
    country: null,
  });

  const { currency } = selectedOption;
  // this is for m-pesa inputs. It defaults to Kenya
  const [selectedCountry, setSelectedCountry] = useState({
    countryCallingCode: '254',
    name: 'Kenya',
    flag: `flag-icon-ke`,
  });

  useEffect(() => {
    const { countryCallingCode, name, flag } =
      getCountryDetailsPerCurrency(recurringPaymentData.currency) || {};

    setSelectedCountry({ countryCallingCode, name, flag });
    handleStepChange({ next: stepperId });
  }, []);

  const handleNext = async () => {
    const fullPhoneNumber = getFullPhoneNumber(
      selectedCountry?.countryCallingCode,
      phoneNumber,
    );
    const isValid = validatePhoneNumber(fullPhoneNumber, phoneNumber);

    if (!isValid) {
      setMobileValidationErrors('Enter a valid phone number e.g. 7********');
      return;
    }
    dispatch(
      recurringPaymentDataState.actions.updateRecurringPaymentData({
        ...recurringPaymentData,
        phoneNumber: fullPhoneNumber,
      }),
    );
    history.push('/recurring-payments/confirm', { previousStep: 2 });
  };

  return (
    <div className="mt-10">
      <>
        <form className="px-md-20 px-3">
          <KycPageContentWrapper>
            <MobileMoney
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              selectedCountry={selectedCountry}
              validationErrors={mobileValidationErros}
            />

            <ButtonsContainer justifyContent="justify-content-between">
              <div className="w-100 mt-2">
                <ButtonsContainer justifyContent="justify-content-between">
                  <CancelButton
                    buttonText="Previous"
                    handleOnClick={() =>
                      history.push('/recurring-payments/payments')
                    }
                    twinButton
                  />

                  <ConfirmButton
                    buttonText={'Next'}
                    handleOnClick={handleNext}
                    twinButton
                    disable={!phoneNumber}
                  />
                </ButtonsContainer>
              </div>
            </ButtonsContainer>
          </KycPageContentWrapper>
        </form>
      </>
    </div>
  );
};

export default PaymentInformation;
