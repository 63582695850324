import React from 'react';

import HorizontalLine from '../../../../helpers/horizontalLine';
import { trackEventMixpanel } from '../../../../eventTracking/mixpanel';
import { DisplaySvg } from '../../../Common/DisplaySvg';

const Document = ({ factSheet, prospectus, iconA, iconB, name }) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        factSheet && trackEventMixpanel('Factsheet_PDF_Open');
        window.open(factSheet ? factSheet : prospectus ? prospectus : '#');
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <img src={iconA} alt="PDFIcon" />
        <p className="text-13">{name}</p>
        <div>
          <DisplaySvg svgUrl={'/media/svg/ndovu/arrow.svg'} />
        </div>
      </div>
      <HorizontalLine color="#DFE2E6" width={'100%'} height={0.1} />
    </div>
  );
};

export default Document;
