import React from 'react';
import Modal from '@material-ui/core/Modal';

import { ConfirmButton } from './ReusableButtons';
import { AnimatedPageContainer } from './AnimatedPageContainer';
import useResponsiveModalStyles from '../../helpers/modalStyles';
import { DisplaySvg } from './DisplaySvg';

export const CommonSuccessModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  btnText,
  title,
  buttons,
  showIcon = true,
  iconName,
  addTitle = true,
}) => {
  const classes = useResponsiveModalStyles('455px');

  const body = (
    <AnimatedPageContainer>
      <div
        className={`${classes.modal} ${moreClasses} border-radius-8 pt-5 pb-8 px-10`}
      >
        {addCloseButton ? (
          <div
            onClick={setOpen}
            className="bg-blue text-white border-radius-3 font-weight-500 ml-auto cursor-pointer"
            style={{
              paddingBottom: '1px',
              paddingLeft: '10px',
              paddingRight: '10px',
              width: '27px',
            }}
          >
            x
          </div>
        ) : null}
        <div className="d-flex justify-content-center align-items-center flex-column">
          {addTitle ? (
            <div className="w-100 modal-summary-header font-weight-600 text-center mb-8">
              {title ? title : 'Success'}
            </div>
          ) : null}

          {showIcon ? (
            <div className="my-10">
              <DisplaySvg
                svgUrl={`/media/svg/ndovu/${
                  iconName ? iconName : 'thumbsup'
                }.svg`}
                width={'72px'}
                height={'72px'}
                className="mb-1 mr-5"
              />
            </div>
          ) : null}
        </div>

        <div className="modal-summary-body text-center">{content}</div>

        {buttons ? (
          buttons
        ) : (
          <ConfirmButton
            buttonText={btnText ? btnText : 'OK'}
            classes={
              'd-flex justify-content-center align-items-center mx-auto mt-10'
            }
            handleOnClick={handleButtonClick}
            buttonWidth={'w-100'}
          />
        )}
      </div>
    </AnimatedPageContainer>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalContainer}
      disableAutoFocus
    >
      <div className={open ? 'inert' : ''}>{body}</div>
    </Modal>
  );
};
