import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import objectPath from 'object-path';

import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { displayInvestorFirstName } from '../../../../app/helpers/displayInvestorFirstName';
import { DisplaySvg } from '../../../../app/modules/Common/DisplaySvg';

export function QuickUserToggler() {
  const { user, investor } = useSelector(state => state.profile);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.user.layout') === 'offcanvas',
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 first-name"
            id="kt_quick_user_toggle"
          >
            <>
              <span className="mr-5 text-14 text-capitalize">
                {displayInvestorFirstName(
                  investor?.investorType,
                  user?.firstName,
                  investor?.businessChamaName,
                  15,
                )}
              </span>
              <DisplaySvg svgUrl={'/media/svg/ndovu/profile-avatar.svg'} />
            </>
          </div>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
