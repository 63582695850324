import React from 'react';

export const Copied = ({ copied }) => {
  return (
    <div
      className={`${
        copied ? 'bg-gray-550 text-black' : 'text-transparent'
      } px-2 py-1 border-radius-4 text-10 mt-1 width-fit-content mx-auto`}
    >
      {'Copied!'}
    </div>
  );
};
