import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import appleStore from '../../../images/appleStore.svg';
import googlePlayStore from '../../../images/googlePlayStore.svg';
import { removeDownloadAppBanner } from '../../Profile/_redux/profileCrud';

export const DownloadAppBanner = ({ isMediumScreen }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.profile);
  const { user } = userProfile;

  const removeDownloadAppBannerFromDashboard = async () => {
    try {
      await removeDownloadAppBanner();

      dispatch(
        investorProfileActions.profileAction({
          ...userProfile,
          user: { ...user, recentTimeDisabledAppDownloadBar: new Date() },
        }),
      );
    } catch (error) {}
  };

  const closeInstallAppCallToAction = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93341 7.99999L13.1334 3.79999C13.4001 3.53332 13.4001 3.13332 13.1334 2.86666C12.8667 2.59999 12.4667 2.59999 12.2001 2.86666L8.00008 7.06666L3.80008 2.86666C3.53341 2.59999 3.13341 2.59999 2.86675 2.86666C2.60008 3.13332 2.60008 3.53332 2.86675 3.79999L7.06675 7.99999L2.86675 12.2C2.73341 12.3333 2.66675 12.4667 2.66675 12.6667C2.66675 13.0667 2.93341 13.3333 3.33341 13.3333C3.53341 13.3333 3.66675 13.2667 3.80008 13.1333L8.00008 8.93332L12.2001 13.1333C12.3334 13.2667 12.4667 13.3333 12.6667 13.3333C12.8667 13.3333 13.0001 13.2667 13.1334 13.1333C13.4001 12.8667 13.4001 12.4667 13.1334 12.2L8.93341 7.99999Z"
        fill="#3E3E3E"
      />
    </svg>
  );

  return (
    <div
      className={`${isMediumScreen ? '' : 'position-absolute fixed-bottom'}`}
    >
      <div
        className={`d-flex justify-content-between ${
          isMediumScreen ? 'align-items-center' : 'mx-2'
        } bg-light-blue-60 border-light-blue-600 p-2 border-radius-5`}
      >
        <div>
          <div className="text-md-13 mb-md-0 mb-2 text-13 font-weight-500">
            Download the Ndovu app
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="d-flex">
              <a
                href="https://app.adjust.com/1mam0trf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appleStore} alt="Apple store" />
              </a>

              <a
                href="https://app.adjust.com/1m94pl8u"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div style={{ width: '100px' }}>
                  <img
                    className="ml-2"
                    src={googlePlayStore}
                    alt="Google Play store"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div
          onClick={() => removeDownloadAppBannerFromDashboard()}
          className="hide-close-bar-one ml-3 font-weight-600 text-15 close-bar cursor-pointer"
        >
          {closeInstallAppCallToAction}
        </div>
      </div>
    </div>
  );
};
