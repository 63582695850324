import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { actions as planReducerActions } from '../Plan/_redux/planReducers';

import PayWithMobileMoney from './components/PayWithMobileMoney';
import { getPayingNowAmount } from '../../helpers/getPayingNowAmount';
import convertAmountToNumber from '../../helpers/convertAmountToNumber';
import { fetchPromotionDetails } from './_redux/investmentAction';
import {
  getCardPaymentModal,
  verifyCardTransaction,
} from '../..//helpers/cardPaymentModal';
import PayWithBankTransfer from './components/PayWithBankTransfer';
import PaymentChannelsAndInvestmentDetails from './components/PaymentChannelsAndInvestmentDetails';
import ApplyVoucher from './components/ApplyVoucher';
import { InvestmentAction, pocketActionsEnum } from '../../helpers/constants';
import PayWithBongaPoints from './components/PayWithBongaPoints';
import Back from '../Common/BackArrow';
import { AnimatedPageContainer } from '../Common/AnimatedPageContainer';
import { getCountryDetailsPerCurrency } from '../../helpers/getCountryDetails';
import Alert from '../Common/Alert';
import ErrorModal from '../Common/ErrorModal';
import { CommonSuccessModal } from '../Common/CommonSuccessModal';
import { pocketContents } from '../Pocket/copies';
import { moneyFormat } from '../../helpers/moneyFormat';

const PlanInvestmentPayment = () => {
  const history = useHistory();
  const location = useLocation();
  const reference = new URLSearchParams(location.search).get('reference');

  const { subscription } = useSelector(state => state.profile);
  const [planInvestmentDtls, setPlanInvestmentDtls] = useState();

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [useVoucher, setUseVoucher] = useState(false);
  const [payWithMobileMoney, setPayWithMobileMoney] = useState(false);
  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [payWithBongaPoints, setpayWithBongaPoints] = useState(false);
  const [openCongratulationsModal, setOpenCongratulationsModal] = useState(
    false,
  );
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [payStackError, setPayStackError] = useState();

  const reduxPlanInvestmentDtls = useSelector(state => state?.plan);
  const { goalInvestmentDetails } = useSelector(state => state?.goal);

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );

  const pocketTopupDetails = useSelector(state => state.pocketTopupDetails);
  const pocketDetails = useSelector(state => state.pocketDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem('discountInfo'));
    setDiscount({
      amount: localStorageData?.discountAmount,
      currency: localStorageData?.currency,
    });
    if (
      reduxPlanInvestmentDtls?.planInvestmentDetails?.amount &&
      localStorageData?.discountAmount
    ) {
      setTotalAmount(
        getPayingNowAmount(
          convertAmountToNumber(
            reduxPlanInvestmentDtls?.planInvestmentDetails?.amount,
          ),
          localStorageData?.discountAmount,
        ),
      );
    }
    // if (
    //   reduxPlanInvestmentDtls?.planInvestmentDetails?.planId ||
    //   reduxPlanInvestmentDtls?.planInvestmentDetails?.fundId
    // ) {
    //   setPlanInvestmentDtls(reduxPlanInvestmentDtls?.planInvestmentDetails);
    // }

    setPlanInvestmentDtls(reduxPlanInvestmentDtls?.planInvestmentDetails);
  }, [reduxPlanInvestmentDtls]);

  const verifyTransaction = async () => {
    setLoading(true);
    try {
      const res = await verifyCardTransaction(reference);
      if (res.status === 200) {
        setOpenCongratulationsModal(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reference && verifyTransaction();
  }, [reference]);

  const initialValues = {
    promoCode: planInvestmentDtls?.planInvestmentDetails?.promoCode,
  };

  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string()
      .min(8, 'Promo code must be 8 character long')
      .max(8, 'Promo code must be 8 character long'),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  // Change Voucher code
  const handleChange = event => {
    const { name, value } = event.target;
    setPlanInvestmentDtls({
      ...planInvestmentDtls,
      [name]: value,
    });
  };

  const alertRef = useRef(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PromoCodeSchema,
  });

  const payWithCreditCard = async channel => {
    if (investmentAction !== InvestmentAction.POCKET_TOPUP) {
      history.push('/new-investment/plans');
      return;
    }

    try {
      setLoading(true);

      const response = await getCardPaymentModal({
        amount: convertAmountToNumber(pocketTopupDetails?.amount),
        currency: pocketTopupDetails?.currency,
        promoCode:
          planInvestmentDtls?.promoCode || localStorage.getItem('promoCode'),
        channel,
        investmentAction,
        pocketId: pocketDetails?.id,
      });

      if (response?.data?.data) {
        const { authorization_url } = response.data.data;

        // Clear relevant local storage items
        [
          'goalDataForPayment',
          'discountInfo',
          'promoCode',
          'planCurrency',
          'promoPlanOnOffer',
        ].forEach(item => localStorage.removeItem(item));

        // Redirect to payment
        window.location.replace(authorization_url);
      }
    } catch (error) {
      setLoading(false);

      setOpenErrorModal(true);
      setPayStackError(error.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  };

  const applyPromoCode = async () => {
    setAlertOn(false);
    if (!planInvestmentDtls.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Promo code can not be empty',
        alertMessageType: 'error',
      });
      return;
    }

    if (formik.errors.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: formik.errors.promoCode,
        alertMessageType: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      localStorage.setItem('promoCode', planInvestmentDtls.promoCode);
      const response = await fetchPromotionDetails(
        planInvestmentDtls.promoCode,
        planInvestmentDtls.currency,
      );
      const {
        data: { data: { amount: discountAmount, currency } = {} } = {},
      } = response;
      setDiscount({ amount: discountAmount, currency });

      const amountNumber = convertAmountToNumber(planInvestmentDtls?.amount);
      const amountTOPay = getPayingNowAmount(amountNumber, discountAmount);

      setTotalAmount(amountTOPay);
      localStorage.setItem(
        'discountInfo',
        JSON.stringify({
          discountAmount,
          currency,
          totalAmount: amountTOPay,
        }),
      );

      dispatch(
        planReducerActions.updatePlanInvestmentDetails(planInvestmentDtls),
      );

      setUseVoucher(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const currencyPerOperations = {
    [InvestmentAction.DIRECT_INVESTMENT]: planInvestmentDtls?.currency,
    [InvestmentAction.DIRECT_INVESTMENT_TOPUP]: planInvestmentDtls?.currency,
    [InvestmentAction.POCKET_TOPUP]: pocketTopupDetails?.currency,
    [InvestmentAction.GOAL_TOPUP]: goalInvestmentDetails?.currency,
    [InvestmentAction.GOAL_INVESTMENT]: goalInvestmentDetails?.currency,
  };

  const amountPerOperations = {
    [InvestmentAction.DIRECT_INVESTMENT]: planInvestmentDtls?.amount,
    [InvestmentAction.DIRECT_INVESTMENT_TOPUP]: planInvestmentDtls?.amount,
    [InvestmentAction.POCKET_TOPUP]: pocketTopupDetails?.amount,
    [InvestmentAction.GOAL_TOPUP]: goalInvestmentDetails?.amount,
    [InvestmentAction.GOAL_INVESTMENT]: goalInvestmentDetails?.amount,
  };

  const modalContent = (
    <>
      {
        pocketContents({
          inputOne: {
            amount: moneyFormat(pocketTopupDetails?.amount),
            currency: pocketTopupDetails?.currency,
          },
        })?.[pocketActionsEnum.TOPUP]?.congratulationsModalContent
      }
    </>
  );

  const renderPaymentOptions = () => {
    if (payWithMobileMoney) {
      return (
        <PayWithMobileMoney
          setPayWithMobileMoney={setPayWithMobileMoney}
          amount={amountPerOperations[investmentAction]}
          promoCode={planInvestmentDtls?.promoCode}
          currency={currencyPerOperations[investmentAction]}
          selectedCountry={getCountryDetailsPerCurrency(
            currencyPerOperations[investmentAction],
          )}
          discountAmount={discount?.amount}
        />
      );
    } else if (payWithBankTransfer) {
      return (
        <PayWithBankTransfer
          amount={amountPerOperations[investmentAction]}
          currency={currencyPerOperations[investmentAction]}
          promoCode={planInvestmentDtls?.promoCode}
          setPayWithBankTransfer={setPayWithBankTransfer}
          planDetails={reduxPlanInvestmentDtls?.viewedPlan}
          activePaymentMethod={activePaymentMethod}
          discountAmount={discount?.amount}
        />
      );
    } else if (payWithBongaPoints) {
      return (
        <PayWithBongaPoints
          totalAmount={totalAmount}
          discount={discount}
          amount={amountPerOperations[investmentAction]}
          currency={currencyPerOperations[investmentAction]}
          promoCode={planInvestmentDtls?.promoCode}
          setpayWithBongaPoints={setpayWithBongaPoints}
          minimumInvestableAmount={
            reduxPlanInvestmentDtls?.viewedPlan?.minInvestableAmounts?.KES
          }
        />
      );
    } else if (useVoucher) {
      return (
        <ApplyVoucher
          alertOn={alertOn}
          setAlertOn={setAlertOn}
          alert={alert}
          formik={formik}
          getInputClasses={getInputClasses}
          investmentDetails={planInvestmentDtls}
          handleChange={handleChange}
          setUseVoucher={setUseVoucher}
          applyPromoCode={applyPromoCode}
          loading={loading}
        />
      );
    } else {
      return (
        <>
          {alertOn && (
            <div ref={alertRef} className="mb-5">
              <Alert alert={alert} />{' '}
            </div>
          )}
          <PaymentChannelsAndInvestmentDetails
            payWithCreditCard={payWithCreditCard}
            setUseVoucher={setUseVoucher}
            loading={loading}
            setLoading={setLoading}
            discount={discount}
            amount={amountPerOperations[investmentAction]}
            currency={currencyPerOperations[investmentAction]}
            subscription={subscription}
            planDetails={reduxPlanInvestmentDtls?.viewedPlan}
            totalAmount={totalAmount}
            payWithMobileMoney={payWithMobileMoney}
            setPayWithMobileMoney={setPayWithMobileMoney}
            payWithBankTransfer={payWithBankTransfer}
            setPayWithBankTransfer={setPayWithBankTransfer}
            setpayWithBongaPoints={setpayWithBongaPoints}
            setAlert={setAlert}
            setAlertOn={setAlertOn}
            alert={alert}
            alertOn={alertOn}
            activePaymentMethod={activePaymentMethod}
            setActivePaymentMethod={setActivePaymentMethod}
            alertRef={alertRef}
          />

          <CommonSuccessModal
            open={openCongratulationsModal}
            setOpen={() => setOpenCongratulationsModal(false)}
            title={'Processing'}
            content={modalContent}
            moreClasses={'bg-white'}
            handleButtonClick={() => {
              history.push(`/pocket/${pocketDetails?.id}`);
            }}
            iconName={'sand-glass'}
          />

          <ErrorModal
            open={openErrorModal}
            setOpen={() => setOpenErrorModal(!openErrorModal)}
            content={
              payStackError ? (
                payStackError
              ) : (
                <span>
                  The payment link was not generated successfully,
                  <span className="font-weight-500">please try again</span>
                </span>
              )
            }
            moreClasses="bg-orange-100"
            buttonText="Try again"
            modalTitle={'Card payment failed'}
          />
        </>
      );
    }
  };

  return (
    <AnimatedPageContainer>
      <Back text="Back" onClick={() => history.goBack()} />
      {renderPaymentOptions()}
    </AnimatedPageContainer>
  );
};

export default PlanInvestmentPayment;
