/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import {
  fetchInvestorAccounts,
  switchInvestorAccounts,
} from '../../../../../app/modules/Business/_redux/busChamaCrud';
import { actions as authActions } from '../../../../../app/modules/Auth/_redux/authRedux';
import { actions as pocketActions } from '../../../../../app/modules/Pocket/_redux/reducers.js';
import { actions as planReduxActions } from '../../../../../app/modules/Plan/_redux/planReducers.js';
import { actions as goalReducerActions } from '../../../../../app/modules/Goal/_redux/goalReducers.js';

import Menu from './Menu';
import logout from '../../../../../app/modules/Auth/pages/Logout';
import { rightSideMenuList } from './rightSideMenuList.js';

import {
  accountTypes,
  teamMemberRole,
} from '../../../../../app/helpers/constants.js';
import { displayInvestorFirstName } from '../../../../../app/helpers/displayInvestorFirstName.js';
import { CustomButton } from '../../../../../app/modules/Common/ReusableButtons/index.js';
import { HorizontalLine } from '../../../../../app/modules/Common/HorizontalLine.js';
import { camelCase, isEqual } from 'lodash';
import { trackEventMixpanel } from '../../../../../app/eventTracking/mixpanel.js';
import { DisplaySvg } from '../../../../../app/modules/Common/DisplaySvg.js';
import Alert from '../../../../../app/modules/Common/Alert.js';

function QuickUser() {
  const { user, investor } = useSelector(state => state.profile);
  const [menuList, setMenuList] = useState(rightSideMenuList);
  const [accountList, setAccountList] = useState([]);
  const [errorMessage, setErrorMessage] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const newArray = [];
    menuList.forEach(element => {
      const menuLink = document.getElementById(element.name);
      const menuTitle = document.getElementById(`${element.name}-title`);

      menuTitle && menuTitle.classList.remove('active-right-menu-link');
      menuLink && menuLink.classList.remove('active-menu');
      menuLink && menuLink.classList.remove('border-radius-4');

      if (location.pathname.startsWith(element.path)) {
        menuLink.classList.add('active-menu');
        menuLink.classList.add('border-radius-4');
        menuTitle.classList.add('active-right-menu-link');
        element.active = true;
      } else {
        element.active = false;
      }
      newArray.push(element);
    });

    if (!isEqual(newArray, menuList)) {
      setMenuList(newArray);
    }

    const getAccounts = async () => {
      try {
        const res = await fetchInvestorAccounts();
        const removeActiveAccount = res.data.data
          ?.filter(item => item.id !== investor?.id)
          ?.map(item => {
            const accountName =
              item?.investorType === accountTypes.INDIVIDUAL
                ? user?.firstName
                : item?.businessChamaName;
            return {
              ...item,
              name: camelCase(accountName),
              hover: false,
              active: false,
            };
          });

        if (!isEqual(removeActiveAccount, accountList)) {
          setAccountList(removeActiveAccount);
        }
      } catch (error) {
        setErrorMessage({
          alertMessage: error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Something went wrong while getting the other accounts',
        });
      }
    };

    getAccounts();
  }, [location.pathname, investor.id, user.firstName]);

  const changeIconOnHover = (name, action) => {
    const newArray = [];
    menuList.forEach(element => {
      if (element.name === name) {
        if (action === 'mouseOver') {
          element.hover = true;
        }
        if (action === 'mouseOut') {
          element.hover = false;
        }
      }
      newArray.push(element);
    });
    setMenuList(newArray);
  };

  const changeIconOnHoverOnInvestorAccount = (name, action) => {
    const newArray = [];
    accountList.forEach(element => {
      if (element.name === name) {
        if (action === 'mouseOver') {
          element.hover = true;
        }
        if (action === 'mouseOut') {
          element.hover = false;
        }
      }
      newArray.push(element);
    });
    setAccountList(newArray);
  };

  const handleHover = (id, isInvestorAccount) => {
    const menuLink = document.getElementById(id);

    menuLink.classList.add('on-hover');
    const linkText = document.getElementById(`${id}-title`);

    linkText.classList.add('active');
    isInvestorAccount
      ? changeIconOnHoverOnInvestorAccount(id, 'mouseOver')
      : changeIconOnHover(id, 'mouseOver');
  };

  const handleMouseOut = (id, isInvestorAccount) => {
    const menuLink = document.getElementById(id);
    const linkText = document.getElementById(`${id}-title`);
    menuLink.classList.remove('on-hover');
    linkText.classList.remove('active');

    isInvestorAccount
      ? changeIconOnHoverOnInvestorAccount(id, 'mouseOut')
      : changeIconOnHover(id, 'mouseOut');
  };

  const logoutClick = () => {
    trackEventMixpanel('Logout');
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    logout();
    dispatch(authActions.resetAuthPageLogo());
  };

  const closeAsideNav = () => {
    let closeAsideButton = document.getElementById('kt_quick_user_close');
    let asideLeftItemElement = document.getElementById('kt_quick_user');
    asideLeftItemElement.classList.remove('offcanvas-on');
    closeAsideButton.click();
  };

  const handleAccountSwitch = async investorId => {
    // TODO: handle error messages
    try {
      const res = await switchInvestorAccounts({ investorId: investorId });

      if (res.status === 201) {
        closeAsideNav();
        localStorage.setItem('ndovuToken', res.data.data.accessToken);
        dispatch(planReduxActions.ResetPlanInvestmentDetails());
        dispatch(goalReducerActions.ResetGoalInvestmentDetails());
        dispatch(pocketActions.updatePocket(null));
        history.push('/');
      } else {
        closeAsideNav();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const toTitleCase = str => {
    return str?.toLowerCase().replace(/\b\w/g, function(char) {
      return char.toUpperCase();
    });
  };

  const accountIcon = {
    INDIVIDUAL: {
      normal: '/media/svg/ndovu/account.svg',
      active: '/media/svg/ndovu/account-white-icon.svg',
    },
    BUSINESS: {
      normal: '/media/svg/ndovu/business-icon.svg',
      active: '/media/svg/ndovu/business-icon-white.svg',
    },
    CHAMA: {
      normal: '/media/svg/ndovu/chama-icon.svg',
      active: '/media/svg/ndovu/chama-icon-white.svg',
    },
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas">
      <div className="offcanvas-header d-flex align-items-center justify-content-end py-4 px-10">
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        {user?.firstName || investor?.businessChamaName ? (
          <div className="d-flex flex-column ml-10">
            <div className="text-primary-orange text-16 font-weight-600 m-0 text-capitalize">
              {displayInvestorFirstName(
                investor?.investorType,
                user?.firstName,
                investor?.businessChamaName,
                20,
              )}
            </div>
            <div className="text-13">
              {toTitleCase(investor?.investorType)} Account
            </div>
          </div>
        ) : null}

        <HorizontalLine
          classes={'navi-spacer-x-0 p-0 mx-10 border-b-gray my-3'}
        />

        <div className="navi navi-spacer-x-0 p-0 mx-10">
          {menuList.map(menu => {
            return menu?.name === 'manageAccount' &&
              user?.businessChamaRole?.name === teamMemberRole.VIEWER ? null : (
              <Menu
                key={menu.name}
                closeAsideNav={closeAsideNav}
                menu={menu}
                handleHover={handleHover}
                handleMouseOut={handleMouseOut}
              />
            );
          })}
        </div>

        <HorizontalLine
          classes={'navi-spacer-x-0 p-0 mx-10 border-b-gray my-3'}
        />

        <div className="navi navi-spacer-x-0 p-0 mx-10 my-5">
          <CustomButton
            buttonText={'Sign Out'}
            classes={
              'd-flex justify-content-center align-items-center bg-primary-orange orange-button-hover text-white mx-auto mt-8'
            }
            handleOnClick={logoutClick}
            buttonWidth={'width-140'}
          />
        </div>

        {errorMessage?.alertMessage ? <Alert alert={errorMessage} /> : null}

        <div className="d-flex flex-column ml-10 mb-2">
          <div className="text-13">Other Linked Accounts</div>
        </div>
        <div className="navi navi-spacer-x-0 p-0 mx-10">
          {accountList?.map(accounts => {
            const accountName =
              accounts?.investorType === accountTypes.INDIVIDUAL
                ? user?.firstName
                : accounts?.businessChamaName;
            return (
              <div
                onClick={() => handleAccountSwitch(accounts.id)}
                className="navi-item link-nav cursor-pointer"
                key={accounts.id}
                onMouseOver={() => handleHover(accounts?.name, true)}
                onMouseOut={() => handleMouseOut(accounts?.name, true)}
              >
                <div id={accounts?.name} className="right-nav pl-5">
                  <div className="symbol symbol-30 mr-1">
                    <img
                      src={
                        accountIcon[accounts?.investorType][
                          accounts?.hover || accounts?.active
                            ? 'active'
                            : 'normal'
                        ]
                      }
                      alt="menu icon"
                      width={25}
                      height={25}
                      style={{ objectFit: 'contain' }}
                    />
                  </div>
                  <div>
                    <div
                      id={`${accounts?.name}-title`}
                      className="text-dark-gray-200 text-13 text-capitalize"
                    >
                      {accountName}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <Link
            onClick={() => {
              trackEventMixpanel('Create_New_Account');
              closeAsideNav();
            }}
            to={'/investor-information/select-account-type'}
            className="navi-item link-nav"
          >
            <div className="d-flex justify-content-center align-items-center white-button-hover height-45 border-primary-orange border-radius-24 pl-5">
              <div className="symbol symbol-30 mr-5">
                <DisplaySvg svgUrl={'/media/svg/ndovu/orange-plus.svg'} />
              </div>
              <div>
                <div
                  id="transactionsHistory-title"
                  className="font-weight-500 text-12 text-primary-orange"
                >
                  Create new account
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ profile: state?.profile });

export default connect(mapStateToProps, null)(QuickUser);
