import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AsideMenuList } from './AsideMenuList';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { DownloadAppBanner } from '../../../../../app/modules/Common/DownloadAppBanner';

export function AsideMenu({ disableScroll }) {
  const { user, loading } = useSelector(state => state.profile);
  const recentTimeDisabledAppDownloadBar =
    user?.recentTimeDisabledAppDownloadBar;

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu ', true),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu mt-1 mb-4 bg-white ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} />

        {!recentTimeDisabledAppDownloadBar && !loading ? (
          <DownloadAppBanner />
        ) : null}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
