import { parsePhoneNumber } from 'libphonenumber-js';
import { PhoneNumberUtil } from 'google-libphonenumber';

export const getFullPhoneNumber = (countryCallingCode, phoneNumber) => {
  return `+${countryCallingCode}${phoneNumber}`;
};

export const validatePhoneNumber = (fullPhoneNumber, nationalPhoneNumber) => {
  if (!fullPhoneNumber || !nationalPhoneNumber) {
    return false;
  }

  const countryCode = parsePhoneNumber(fullPhoneNumber).country;

  const phoneUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(
    nationalPhoneNumber,
    countryCode,
  );

  const isValid = phoneUtil.isValidNumber(number);

  return isValid;
};
