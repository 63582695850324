import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import InvestAndSaveIcon from '../../../icons/investBtnIcon.svg';
import { messages } from '../../../utils/messages';
import SavingAccountMesssage from '../../Plan/components/SavingAccoutMessage';
import SavingPlanCard from '../../Plan/components/SavingPlanCard';

import { trackEventMixpanel } from '../../../eventTracking/mixpanel';

import * as profile from '../../Profile/_redux/profileRedux';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';
import * as plansDailyTrade from '../_redux/dashboardReducers';
import { actions as kycReminderAction } from '../_redux/dashboardReducers';

import { fetchSinglePlanDetails } from '../../Plan/_redux/plansActions';
import { actions as pocketActions } from '../../Pocket/_redux/reducers';

import { actions as conversionActions } from '../../Account/_redux/conversions/conversionReducers';
import { actions as currencyActions } from '../../Account/_redux/currencies/currenciesReducers';

import { fetchUserPockets } from '../../Pocket/_redux/axiosRequest';
import { actions as pocketsActions } from '../../Pocket/_redux/reducers';
import {
  fetchLearningVideos,
  fetchPocketsAndPortfolioValues,
  fetchTransactionsTimeline,
  updateInvestorOnboardingInformation,
} from '../_redux/dashboardHttpRequests';

import { fetchConversions } from '../../Account/_redux/conversions/conversionActions';
import { fetchCurrencies } from '../../Account/_redux/currencies/currencyActions';

import { eventTracking } from '../../../eventTracking/httpRequest';
import useUserProfile from '../../../helpers/CustomHook/useUserProfile';
import { customerIOEventType } from '../../../helpers/constants';
import { displayInvestorFirstName } from '../../../helpers/displayInvestorFirstName';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import PageBottomContent from '../../../helpers/pageBottomContent';
import Alert from '../../Common/Alert';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import ErrorModal from '../../Common/ErrorModal';
import {
  ConfirmButton,
  CustomButton,
  TextButton,
} from '../../Common/ReusableButtons';
import { Titles } from '../../Common/Titles';
import { fetchHotFunds } from '../../Plan/_redux/plansActions';
import { HowItWorksModalContent } from '../components/HowItWorksModalContent';
import { PortfolioValueAndPocket } from '../components/PortfolioValueAndPocket';
import { Timeline } from '../components/Timeline/Timeline';
import kycRejectionModalHeaderIcon from '../dashboard-images/kycRejectionHeaderIcon.svg';
import { Promo } from './Promo';
import { LearnCard } from './learnCard';
import { DownloadAppBanner } from '../../Common/DownloadAppBanner';
import { defaultErrorMessage } from '../../../helpers/constants';
import MessageAlert from '../../Common/MessageAlert';

const Dashboard = ({ plansDailyTradingAction }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(false);
  const { REACT_APP_ORIGIN_PARTNER_PLAN_ID } = process.env;

  const userProfile = useSelector(state => state.profile);

  const { investor, user } = userProfile;
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const displayKycRejectionReason = useSelector(
    state => state.displayKycRejectionReason,
  );

  const { originPartner } = investor;
  const recentTimeDisabledAppDownloadBar =
    user?.recentTimeDisabledAppDownloadBar;

  const [pocketAndPortfolioValues, setPocketAndPortfolioValues] = useState();
  const [openHowItWorksModal, setOpenHowItWorksModal] = useState(
    user?.isNdovuModalViewed,
  );
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [
    updatingOnboardingInformation,
    setUpdatingOnboardingInformation,
  ] = useState(false);
  const [transactionsTimeline, setTransactionsTimeline] = useState();

  const { updateInvestorProfile } = useUserProfile();

  const dispatch = useDispatch();

  const onMobile = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(max-width: 991px)');

  const updateErrorMessage = errorMessage => {
    setAlert({
      alertMessage: errorMessage,
      alertMessageType: 'alert-light-danger',
    });
  };

  const fetchSavingPlan = async planId => {
    try {
      const res = await fetchSinglePlanDetails(planId);
      setPlan(res.data.data);
    } catch (error) {
      updateErrorMessage(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : defaultErrorMessage,
      );
      setLoading(false);
    }
  };

  const [plans, setPlans] = useState(false);
  const [videos, setVideos] = useState([]);

  const fetchVideos = async () => {
    try {
      const res = await fetchLearningVideos(4);
      const data = res.data;
      setVideos(data);
      setLoading(false);
    } catch (error) {
      updateErrorMessage(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : defaultErrorMessage,
      );
      setLoading(false);
    }
  };

  const fetchPlans = async () => {
    try {
      const res = await fetchHotFunds();
      const plansOnOffer = res.data.data;

      setPlans(plansOnOffer);
      setLoading(false);
    } catch (error) {
      updateErrorMessage(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : defaultErrorMessage,
      );
      setLoading(false);
    }
  };

  const renderSavingsPlan = () => {
    return (
      <div id="dashboard-saving-plan" className="mt-2 mb-4">
        <div className="p-3 saving-plans-container px-5">
          <div className="mr-5" key={plan?.id}>
            <SavingPlanCard
              data={plan}
              key={plan?.id}
              planTypeName={firstLetterUpperCase(plan?.planType?.name)}
              url={`/plans/fixed-return/${plan?.id}`}
            />
          </div>
        </div>

        <SavingAccountMesssage
          message={messages.savingFundAlert}
          classes="mx-7 mb-7"
        />
      </div>
    );
  };

  const fetchPockets = async () => {
    try {
      const response = await fetchUserPockets();
      dispatch(pocketsActions.updatePockets(response?.data?.items));
    } catch (error) {
      updateErrorMessage(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : defaultErrorMessage,
      );
    }
  };

  const getPortfolioValues = async () => {
    try {
      const response = await fetchPocketsAndPortfolioValues();
      setPocketAndPortfolioValues(response?.data?.data);
    } catch (error) {
      updateErrorMessage(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : defaultErrorMessage,
      );
    }
  };

  const getTransactionsTimeline = async () => {
    try {
      const response = await fetchTransactionsTimeline();
      setTransactionsTimeline(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('previousPage');
    localStorage.removeItem('previousURL');

    if (originPartner) {
      fetchSavingPlan(Number(REACT_APP_ORIGIN_PARTNER_PLAN_ID));
    }

    fetchPlans();
    fetchVideos();
    fetchPockets();
    getPortfolioValues();
    getTransactionsTimeline();

    dispatch(pocketActions.updatePocket(null));
    localStorage.removeItem('pathName');
  }, [plansDailyTradingAction]);

  useEffect(() => {
    const getData = async () => {
      try {
        const {
          data: { data: allCurrencies },
        } = await fetchCurrencies();

        dispatch(currencyActions.currencyAction(allCurrencies));
      } catch (error) {
        updateErrorMessage(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : defaultErrorMessage,
        );
      }

      try {
        const {
          data: { data: allConversions } = {},
        } = await fetchConversions();

        dispatch(conversionActions.conversionAction(allConversions));
      } catch (error) {
        updateErrorMessage(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : defaultErrorMessage,
        );
      }
    };

    getData();
  }, [dispatch]);

  useEffect(() => {
    updateInvestorProfile();
  }, [updateInvestorProfile]);

  useEffect(() => {
    const getData = async () => {
      const {
        data: { data: allCurrencies },
      } = await fetchCurrencies();
      const { data: { data: allConversions } = {} } = await fetchConversions();
      dispatch(currencyActions.currencyAction(allCurrencies));
      dispatch(conversionActions.conversionAction(allConversions));
    };

    getData();
  }, [dispatch]);

  useEffect(() => {
    trackEventMixpanel('Visit_Home_Page');
  }, []);

  const handleKycRejectionModalButton = () => {
    localStorage.setItem('previousPage', history.location.pathname);
    const link = handleKycRouting(kycChecks, '/manage-account', investorType);

    return link;
  };

  const closeKycRejectionReasonModal = () => {
    dispatch(kycReminderAction.displayKycRejectionReasonModal(false));
  };

  const handleInvestButtonClick = async () => {
    trackEventMixpanel('Click_Invest_Home');
    try {
      await eventTracking(customerIOEventType.INVEST_ON_HOME_PAGE);
    } catch (error) {}
  };

  const closeHowItWorksModal = async action => {
    action === 'invest' && setUpdatingOnboardingInformation(true);
    try {
      await updateInvestorOnboardingInformation();

      dispatch(
        investorProfileActions.profileAction({
          ...userProfile,
          user: { ...userProfile?.user, isNdovuModalViewed: true },
          loading: false,
        }),
      );

      setUpdatingOnboardingInformation(false);

      action === 'invest'
        ? history.push('/plans')
        : setOpenHowItWorksModal(true);
    } catch (error) {
      setUpdatingOnboardingInformation(false);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'alert-light-danger',
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <>
          {alert?.alertMessage ? (
            <MessageAlert
              closeAlert={() => setAlert({ ...alert, alertMessage: null })}
              alert={alert}
            />
          ) : null}
          <div className="d-flex justify-content-between align-items-center">
            {onMobile ? (
              <div className="text-13 font-weight-500">
                Hello and Welcome
                <br />
                <span className="text-primary-orange font-weight-600 text-13">
                  {' '}
                  {displayInvestorFirstName(
                    investor?.investorType,
                    user?.firstName,
                    investor?.businessChamaName,
                    20,
                  )}
                </span>
              </div>
            ) : (
              <div className="text-md-16 text-16 font-weight-500">
                Your Assets
              </div>
            )}

            <Link to="/plans" onClick={handleInvestButtonClick}>
              <CustomButton
                buttonText={
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={InvestAndSaveIcon} alt={'Invest and save icon'} />
                    <span className="ml-2 font-weight-500">Invest</span>
                  </div>
                }
                loading={loading}
                buttonWidth={onMobile ? 'button-md-w-121' : 'width-140'}
                classes={'bg-primary-orange orange-button-hover text-white'}
              />
            </Link>
          </div>

          <>
            {onMobile ? (
              <div className="text-md-16 text-16 font-weight-500 mt-5">
                Your Assets
              </div>
            ) : null}
          </>

          {alert?.alertMessage ? <Alert alert={alert} /> : null}

          <>
            {pocketAndPortfolioValues && pocketAndPortfolioValues?.length ? (
              <div className="mt-3">
                {/* TODO: <PotentialCurrencies investment={investments} /> */}
                <div
                  className="d-flex w-100 overflow-auto pb-5"
                  style={{ gap: '20px' }}
                >
                  {pocketAndPortfolioValues && pocketAndPortfolioValues?.length
                    ? pocketAndPortfolioValues.map((portfolioValue, index) => (
                        <PortfolioValueAndPocket
                          key={index}
                          portfolioValueDetails={portfolioValue}
                        />
                      ))
                    : null}
                  {/* 🔴 DO NOT REMOVE THIS AddNewCurrency COMPONENT. IT WILL BE NEEDED IN FUTURE 🔴 */}

                  {/* <AddNewCurrency /> */}
                </div>
              </div>
            ) : null}

            {/* 
              IMPORTANT: For kyosk users, they only need to see the profit and portfolio value card only. 
              Thus, any new items in the dashboard should be exempted for these users. To determine this users, we check the boolean field "originPartner".
            */}

            {transactionsTimeline?.length ? (
              <Timeline transactionsTimeline={transactionsTimeline} />
            ) : null}

            {originPartner && plan ? (
              renderSavingsPlan()
            ) : (
              <>
                <Promo plans={plans} />
                <div className="d-flex flex-column mt-10">
                  <div className="d-flex justify-content-between font-weight-500 mb-5">
                    <Titles title={'Learn how to grow your wealth'} />
                    {videos?.meta?.totalItems > videos?.items?.length ? (
                      <a
                        href="https://www.ndovu.co/ndovu-videos"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue"
                      >
                        <Titles title="See More" />
                      </a>
                    ) : null}
                  </div>

                  <div className="flex-row d-flex overflow-auto learn">
                    <LearnCard videos={videos} />
                  </div>
                </div>
              </>
            )}

            {!recentTimeDisabledAppDownloadBar && isMediumScreen ? (
              <div className="mt-10">
                <DownloadAppBanner isMediumScreen={isMediumScreen} />
              </div>
            ) : null}

            <PageBottomContent />

            <ErrorModal
              open={displayKycRejectionReason}
              setOpen={() => closeKycRejectionReasonModal()}
              content={
                <span>
                  The documents you provide do not meet the required criteria
                  because{' '}
                  <span className="font-weight-500">
                    {investor?.kycRejectionReason?.description}
                  </span>
                </span>
              }
              moreClasses="bg-orange-100"
              generateModalButtonRedirectLink={handleKycRejectionModalButton}
              buttonText="Update"
              headerIcon={kycRejectionModalHeaderIcon}
              modalTitle={'KYC Rejected'}
            />

            <CommonSuccessModal
              open={!openHowItWorksModal}
              setOpen={() => setOpenHowItWorksModal(false)}
              content={<HowItWorksModalContent />}
              moreClasses={'bg-white'}
              addTitle={false}
              iconName={'trophy'}
              buttons={
                <div className="d-flex flex-column mx-auto mt-5">
                  <ConfirmButton
                    buttonText={'Invest'}
                    loading={updatingOnboardingInformation}
                    handleOnClick={() => closeHowItWorksModal('invest')}
                    classes={'mx-auto'}
                  />

                  <TextButton
                    buttonText={'Not Now'}
                    handleButtonClick={() => closeHowItWorksModal('dismiss')}
                  />
                </div>
              }
            />
          </>
        </>
      )}
    </>
  );
};

export default connect(null, {
  ...profile.actions,
  ...plansDailyTrade.actions,
})(Dashboard);
