import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';

import { trackEventMixpanel } from '../../../../eventTracking/mixpanel';
import { PendingAmountAndCurrency } from './PendingAmountAndCurrency';
import { PortfolioValue } from './PortfolioValue';
import { PocketBalance } from './PocketBalance';

export const PortfolioValueAndPocket = ({ portfolioValueDetails }) => {
  const {
    currency,
    portfolioValue,
    pocketBalance,
    pocketId,
    currencyFlag,
    totalPendingAmount,
    profitAmount,
    profitPercentage,
  } = portfolioValueDetails;
  const history = useHistory();
  const pocketIsActive = pocketBalance || pocketBalance === 0;

  const isProfitLessThanZero = profitAmount < 0;

  const handleClick = (link, eventName) => {
    eventName && trackEventMixpanel(eventName);
    pocketIsActive && history.push(link);
  };

  return (
    <motion.div
      className="dashboard-asset-card border-light-blue-540 px-5 pt-5 pb-3 border-radius-7"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.6,
        scale: {
          type: 'spring',
          damping: 15,
          stiffness: 50,
          restDelta: 0.001,
        },
      }}
    >
      <PendingAmountAndCurrency
        currency={currency}
        currencyFlag={currencyFlag}
        totalPendingAmount={totalPendingAmount}
      />

      <PortfolioValue
        handleClick={handleClick}
        profitAmount={profitAmount}
        profitPercentage={profitPercentage}
        isProfitLessThanZero={isProfitLessThanZero}
        portfolioValue={portfolioValue}
      />

      <PocketBalance
        pocketBalance={pocketBalance}
        pocketId={pocketId}
        pocketIsActive={pocketIsActive}
        handleClick={handleClick}
      />
    </motion.div>
  );
};
