import React from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { moneyFormat } from '../../../../helpers/moneyFormat';

export const PocketBalance = ({
  handleClick,
  pocketId,
  pocketIsActive,
  pocketBalance,
}) => {
  return (
    <motion.div
      onClick={() => handleClick(`/pocket/${pocketId}`)}
      className={`d-flex align-items-center bg-gray-430 px-2 py-1 border-radius-4 mt-4 ${
        pocketIsActive ? 'cursor-pointer' : ''
      }`}
      whileHover={pocketIsActive ? { scale: 1.05 } : null}
      transition={pocketIsActive ? { type: 'spring', stiffness: 100 } : null}
    >
      <div>
        <SVG src={toAbsoluteUrl('/media/svg/ndovu/dashboard/pocket.svg')} />
      </div>
      <div className="ml-3">
        <div className="text-light-blue-600 text-12">Pocket</div>
        <div className="text-14 font-weight-500">
          {moneyFormat(pocketBalance)}
        </div>
      </div>
    </motion.div>
  );
};
