import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const CREATE_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals`;
const FETCH_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals/suggested-goal-names`;
const GOAL_TOPUP_URL = `${REACT_APP_BASE_URL}/v1/investor/goals/`;
const INVESTMENT_IN_A_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals/`;
const MPESA_GOAL_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/mobile-money/goal-investment`;
const FETCH_GOALS_WITH_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/goals`;
const GOAL_PIECHART_CONTENT = `${REACT_APP_BASE_URL}/v1/investor/goals/goal-portfolio-pie-chart`;

const createGoal = async payload => {
  const res = await axios.post(CREATE_GOAL, payload);
  return res;
};

const goalTopUp = async (goalId, txReference, channel, transactionId) => {
  const res = await axios.post(`${GOAL_TOPUP_URL}${goalId}/goalTopUp`, {
    txReference,
    channel,
    transactionId,
  });
  return res;
};

const getGoals = async () => {
  const res = await axios.get(FETCH_GOAL);
  return res;
};

const getGoalPieChartContent = async () => {
  const res = await axios.get(GOAL_PIECHART_CONTENT);
  return res;
};

const getGoalsWithDetails = async () => {
  const res = await axios.get(FETCH_GOALS_WITH_DETAILS);
  return res;
};

const investmentInGoal = async (
  goalId,
  txReference,
  channel,
  transactionId,
) => {
  const res = await axios.post(`${INVESTMENT_IN_A_GOAL}${goalId}/investment`, {
    txReference,
    channel,
    transactionId,
  });
  return res;
};

/**
 * Handle goal investment using MPESA
 * @param {number} id Identification of the goal
 * @param {string} phoneNumber user's phone number to charge
 * @param {string} amount Amount to pay with no promo code applied
 * @param { enum } type type of investment <INITIAL_INVESTMENT | TOP_UP>
 * @param {string} promoCode promotion code if any. It can be null.
 * @param {string} currency currency. It is required.
 * @param {paymentOption} paymentOption Payment method. It is required.
 */
const goalInvestmentWithMobileMoney = async payload => {
  return axios.post(MPESA_GOAL_INVESTMENT, payload);
};

export {
  createGoal,
  getGoals,
  getGoalsWithDetails,
  goalTopUp,
  investmentInGoal,
  goalInvestmentWithMobileMoney,
  getGoalPieChartContent,
};
