import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Pagination from '@material-ui/lab/Pagination';

import { ConfirmButton } from '../../Common/ReusableButtons';
import { CreditInvestmentSummary } from './CreditInvestmentSummary';
import { getInvestmentCredit } from '../_redux/referralActions';
import { referralStatus } from '../../../helpers/constants';
import Loader from '../../Common/Loader';
import { ReferralStatusTab } from './ReferralStatusTab';
import { capitalize } from 'lodash';

export const InvestmentCredit = ({ changeTab }) => {
  const textColor = {
    [referralStatus.INVESTED]: 'text-blue',
    [referralStatus.PENDING]: 'text-primary-orange',
  };

  const defaultValues = {
    totalRecord: 0,
    totalPages: 0,
    page: 1,
    limit: 10,
    referees: [],
  };

  const [loading, setLoading] = useState(true);
  const [investmentCredits, setInvestmentCredits] = useState({
    [referralStatus.INVESTED]: defaultValues,
    [referralStatus.PENDING]: defaultValues,
  });
  const [alert, setAlert] = useState(null);
  const [creditStatus, setCreditStatus] = useState();
  const [changingStatus, setChangingStatus] = useState(false);

  const currentStatus = creditStatus || referralStatus.INVESTED;

  const updateRefereeList = (meta, items, selectedStatus) => {
    setInvestmentCredits(prevState => ({
      ...prevState,
      [selectedStatus]: {
        ...prevState[selectedStatus],
        ...meta,
        page: meta.currentPage,
        referees: [...items],
      },
    }));
  };

  const fetchInvestmentCredit = async statusList => {
    setChangingStatus(true);

    try {
      const responses = await Promise.all(
        statusList.map(status =>
          getInvestmentCredit(
            status,
            investmentCredits[status]?.page,
            investmentCredits[status]?.limit,
          ),
        ),
      );

      responses.forEach(({ data: { meta, items } }, index) => {
        updateRefereeList(meta, items, statusList[index]);
      });

      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage:
          error?.response?.data?.message || 'Something went wrong, Try again.',
        alertMessageType: 'error',
      });
    } finally {
      setChangingStatus(false);
    }
  };

  const getInvestmentCreditData = () => {
    const { page, limit, referees, totalPages } = investmentCredits[
      currentStatus
    ];

    return { page, limit, referees, totalPages };
  };

  useEffect(() => {
    if (!creditStatus) {
      fetchInvestmentCredit([referralStatus.PENDING, referralStatus.INVESTED]);
    } else {
      fetchInvestmentCredit([creditStatus]);
    }
  }, [creditStatus]);

  const { page, limit, referees, totalPages } = getInvestmentCreditData();

  const handlePageChange = async (_, newPage) => {
    const response = await getInvestmentCredit(creditStatus, newPage, limit);
    updateRefereeList(response.data.meta, response.data.items, creditStatus);
  };

  const changeStatus = status => {
    if (status === creditStatus) return;
    setInvestmentCredits({
      ...investmentCredits,
      [status]: { ...investmentCredits[status], referees: [] },
    });
    setCreditStatus(status);
  };

  const hasReferrals =
    investmentCredits[referralStatus.INVESTED].referees?.length ||
    investmentCredits[referralStatus.PENDING].referees?.length;
  const hasCurrentStatusReferrals = referees?.length;
  const refereeStatusTextColor = textColor[currentStatus];

  const renderNoReferrals = message => (
    <div className="referral-content-container mx-auto d-flex flex-column align-items-center mt-2">
      <SVG src={`/media/svg/ndovu/search.svg`} />
      <div className="mt-7 mb-10 text-14 font-weight-500">{message}</div>
      <ConfirmButton
        buttonText="Refer & Earn"
        handleOnClick={() => changeTab(null, 0)}
        buttonWidth="width-342"
      />
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <div className="referral-content-container mx-auto">
          <CreditInvestmentSummary />

          {alert?.alertMessage ? (
            <div className="text-center text-red-500">{alert.alertMessage}</div>
          ) : (
            <div className="mt-5">
              {hasReferrals ? (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <ReferralStatusTab
                      status={referralStatus.INVESTED}
                      changeStatus={changeStatus}
                      label="Invested"
                      selectedStatus={currentStatus}
                    />
                    <ReferralStatusTab
                      status={referralStatus.PENDING}
                      changeStatus={changeStatus}
                      label="Pending"
                      selectedStatus={currentStatus}
                    />
                  </div>

                  <div className="d-flex flex-wrap justify-content-between">
                    {hasCurrentStatusReferrals
                      ? referees.map(referee => (
                          <div
                            key={referee?.id}
                            className="referee-card d-flex justify-content-between align-items-center border-gray border-radius-8 p-5 mt-2"
                          >
                            <div className="text-12 font-weight-600">
                              {referee?.accountOwner?.firstName}
                            </div>
                            <div
                              className={`${refereeStatusTextColor} text-12 font-weight-500`}
                            >
                              {capitalize(referee?.referralStatus)}
                            </div>
                          </div>
                        ))
                      : !changingStatus &&
                        renderNoReferrals(
                          `You have no ${capitalize(
                            currentStatus,
                          )} referral. Refer now to earn.`,
                        )}
                  </div>

                  <div className="d-flex justify-content-between flex-wrap mt-10">
                    <Pagination
                      page={page}
                      count={totalPages}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                !changingStatus &&
                renderNoReferrals(
                  'You have not referred anyone. Refer now to earn.',
                )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
