import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { getReferralBonus } from '../_redux/referralActions';
import { moneyFormat } from '../../../helpers/moneyFormat';

export const CreditInvestmentSummary = () => {
  const [totalEarning, setTotalEarning] = useState();

  const getTotalEarning = async () => {
    try {
      const { data: { data } = {} } = (await getReferralBonus()) || {};
      data?.amount &&
        setTotalEarning({ currency: data?.currency, amount: data?.amount });
    } catch (error) {}
  };

  useEffect(() => {
    getTotalEarning();
  }, []);

  return (
    <div className="investment-credit-summary position-relative border-radius-4 py-5 mt-5">
      <div className="position-absolute right-0 top-0">
        <SVG src={`/media/svg/ndovu/right-confetti.svg`} />
      </div>

      <div className="position-absolute bottom-0 left-0">
        <SVG src={`/media/svg/ndovu/left-confetti.svg`} />
      </div>

      <div className="text-14 font-weight-500 text-center text-white">
        Refer and Earn upto
      </div>
      <div className="text-20 font-weight-700 text-center mt-5 text-white">
        KES 1000
      </div>
      <div className="text-14 font-weight-500 text-center mt-5 text-white">
        for a single referral
      </div>
      <div className="text-center mt-5">
        <SVG src={`/media/svg/ndovu/gold-coin.svg`} />
      </div>
      <div className="text-14 text-orange-350 font-weight-500 text-center mt-5">
        Total Earnings :{' '}
        {totalEarning
          ? `${totalEarning?.currency} ${moneyFormat(totalEarning?.amount)}`
          : '0'}
      </div>
    </div>
  );
};
