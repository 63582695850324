import React from 'react';
import { DisplaySvg } from '../../Common/DisplaySvg';

export const ShowMoreOrLess = ({
  text,
  iconUrl,
  toggleShowMoreOrLess,
  loading,
}) => {
  return (
    <div className="d-flex justify-content-end mt-5">
      {loading ? (
        // <div>
        <div
          style={{ marginRight: '25px' }}
          className={`spinner spinner-black mt-5`}
        />
      ) : (
        // </div>
        <div
          className="d-flex align-items-center cursor-pointer w-auto"
          onClick={toggleShowMoreOrLess}
        >
          <div className="text-14 font-weight-500">{text}</div>
          <div className="ml-2">
            <DisplaySvg svgUrl={iconUrl} />
          </div>
        </div>
      )}
    </div>
  );
};
