import React from 'react';
import SVG from 'react-inlinesvg';

import { kycStatuses } from '../../../helpers/constants';
import { DisplaySvg } from '../DisplaySvg';

const kycReminderMessage = {
  [kycStatuses.PENDING]: {
    title: 'Additional information required',
    description: 'Verify your identity to start earning.',
    icon: 'pending-kyc',
  },
  [kycStatuses.REJECTED]: {
    title: `Your submission has been rejected`,
    description: 'Review your ID & Photo verification and try again.',
    icon: 'rejected-kyc',
  },

  [kycStatuses.SUBMITTED]: {
    title: `Your submission is under review`,
    description: 'Verification approval may take up to 48 hours.',
    icon: 'submitted-kyc',
  },
  [kycStatuses.IN_EDIT]: {
    title: `Update your Information`,
    description: 'Update your information to continue earning.',
    icon: 'in-edit-kyc',
  },
};

export const PendingAndInEditKycBanner = ({ handleClick, kycStatus }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center border-radius-5 border-light-blue-460 bg-light-blue-455 px-3 mb-5 py-2">
        <div className="d-flex align-items-center">
          <SVG
            src={`/media/svg/ndovu/${kycReminderMessage[kycStatus].icon}.svg`}
          />
          <div className="d-flex flex-column ml-2">
            <div className="text-blue text-16 font-weight-600">
              {kycReminderMessage[kycStatus].title}
            </div>
            <div className="text-13">
              {kycReminderMessage[kycStatus].description}
            </div>
          </div>
        </div>

        <div>
          <div className="d-flex align-items-center">
            <span
              onClick={handleClick}
              className="mr-2 kyc-reminder-text text-blue text-decoration-underline cursor-pointer"
            >
              {kycStatus === kycStatuses.PENDING ? 'Complete' : 'Update'}
            </span>
            <span className="kyc-icon">
              <SVG src={`/media/svg/ndovu/bell-blue-icon.svg`} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export const SubmittedKycBanner = ({ kycStatus }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-5 px-3 py-2 border-orange-500 border-radius-5">
        <SVG
          src={`/media/svg/ndovu/${kycReminderMessage[kycStatus].icon}.svg`}
        />
        <div className="d-flex flex-column ml-2">
          <div className="text-orange-650 text-16 font-weight-600">
            {kycReminderMessage[kycStatus].title}
          </div>
          <div className="text-13">
            {kycReminderMessage[kycStatus].description}
          </div>
        </div>
      </div>
    </>
  );
};

export const RejectedKycBanner = ({ kycStatus, handleClick }) => {
  return (
    <>
      <div
        className="d-flex align-items-center border-red-300 border-radius-5 px-3 py-2 mb-5 cursor-pointer"
        onClick={handleClick}
      >
        <DisplaySvg
          svgUrl={`/media/svg/ndovu/${kycReminderMessage[kycStatus].icon}.svg`}
        />
        <div className="d-flex flex-column ml-2">
          <div className="text-16 text-red-350 font-weight-600">
            {kycReminderMessage[kycStatus].title}
          </div>
          <div className="text-13">
            {kycReminderMessage[kycStatus].description}
          </div>
        </div>
      </div>
    </>
  );
};
