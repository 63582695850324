import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';

import ArrowUpIcon from '../../icons/arrow-up.svg';
import useResponsiveModalStyles from '../../helpers/modalStyles';

const BankModal = ({
  open,
  handleSelect,
  handleClose,
  items,
  selectedItem,
  name,
  addSearch,
  placeholder,
}) => {
  const [filteredOptions, setFilteredOptions] = useState();

  const classes = useResponsiveModalStyles('455px', '90vh');

  useEffect(() => {
    selectedItem &&
      items &&
      items.forEach((item, i) => {
        if (item.name === selectedItem.name) {
          items.splice(i, 1);
          items.unshift(item);
        }
      });
    setFilteredOptions(items);
  }, [items, selectedItem]);

  const handleChange = event => {
    const { value } = event.target;
    const searchResults = items.filter(
      item =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredOptions(searchResults);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalContainer}
    >
      <div className={classes.modal}>
        {addSearch ? (
          <input
            onChange={handleChange}
            type="text"
            name="searchItem"
            className="search-input mb-5"
            placeholder="Search"
            autoComplete="off"
          />
        ) : null}
        <div className="d-flex justify-content-between align-items-center">
          <div className="ml-2">{placeholder}</div>
          <div className="cursor-pointer" onClick={() => handleClose()}>
            <img src={ArrowUpIcon} alt="Select Icon" />
          </div>
        </div>

        <div className="border-b-light-blue-400 my-3"></div>
        {filteredOptions?.map(item => (
          <div key={item.bankId}>
            <div
              className="d-flex justify-content-between align-items-center mt-1 cursor-pointer"
              onClick={() => handleSelect(item, name)}
            >
              <div
                style={{
                  color: selectedItem?.bankId === item?.bankId ? '#0071CE' : '',
                }}
                className="ml-2"
              >
                {item?.name}
              </div>

              <div>
                {selectedItem?.name === item.name ? (
                  <i
                    className="fa fa-check"
                    style={{ color: '#0071CE', fontSize: '12px' }}
                    aria-hidden="true"
                  ></i>
                ) : null}
              </div>
            </div>
            <div className="border-b-light-blue-400 my-3"></div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default BankModal;
