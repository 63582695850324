import React from 'react';
import './Style.scss';
import { useHistory } from 'react-router';

import './Style.scss';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';
import { DisplaySvg } from '../../Common/DisplaySvg';

const AddButton = ({ linkTo, eventName }) => {
  const history = useHistory();
  return (
    <button
      className="add-button  bg-blue text-white border-radius-4"
      onClick={() => {
        trackEventMixpanel(eventName);
        history.push(linkTo);
      }}
    >
      <DisplaySvg svgUrl={'/media/svg/ndovu/plus.svg'} />
      <span className="text-uppercase font-weight-500">Add</span>
    </button>
  );
};

export default AddButton;
