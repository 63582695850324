import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { connect, useSelector } from 'react-redux';

import {
  fetchInvestorDevices,
  removeDevices,
} from '../../_redux/devices/devicesAction';
import * as device from '../../_redux/devices/deviceReducers';

import Alert from '../../../Common/Alert';
import Device from './Device';
import Back from '../../../Common/BackArrow';
import { FullWidthWrapper } from '../../../Common/FullWidthWrapper';
import { SettingsPageHeaderSection } from '../SettingsPageHeaderSection';
import { ConfirmDeleteModal } from '../../../Common/ConfirmDeleteModal';

const Devices = ({ deviceAction }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { devices } = useSelector(state => state.devices) || [];

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [selectedDevice, setSelectedDevice] = useState();

  useEffect(() => {
    const getDevices = async () => {
      try {
        const {
          data: { data },
        } = await fetchInvestorDevices();
        deviceAction(data);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: 'Device was not deleted, please try again',
          alertMessageType: 'alert-light-danger',
        });
      }
    };
    getDevices();
  }, [deviceAction]);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await removeDevices(selectedDevice.id);

      const remainedDevices = devices.filter(
        device => device.id !== selectedDevice.id,
      );

      deviceAction(remainedDevices);
      setLoading(false);
      setOpenConfirmation(false);
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Device was not deleted, please try again',
        alertMessageType: 'error',
      });
      setLoading(false);
      setOpenConfirmation(false);
    }
  };

  const openConfirmationModal = device => {
    setSelectedDevice(device);
    setOpenConfirmation(true);
  };

  return (
    <>
      <Back text="Back" title={'Devices'} />
      <FullWidthWrapper>
        <SettingsPageHeaderSection
          title={'Devices'}
          subtitle={
            'These are the devices currently allowed to access your account'
          }
        />

        {alertOn && <Alert alert={alert} />}
        {devices && devices.length > 0 ? (
          devices.map(device => (
            <div key={device.id}>
              <Device device={device} handleDelete={openConfirmationModal} />
              {devices.length > 1 && <div className="border-top my-3"></div>}
            </div>
          ))
        ) : (
          <div>No Device Found</div>
        )}
      </FullWidthWrapper>

      <ConfirmDeleteModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        moreClasses={'bg-white'}
        content={
          <div className="text-15">
            Are you sure you want to delete this device?
          </div>
        }
        title={'Delete'}
        handleButtonClick={handleDelete}
        buttonText={'Delete'}
        loading={loading}
      />
    </>
  );
};

const mapStateToProps = state => ({
  devices: state.devices.devices,
});

export default connect(mapStateToProps, { ...device.actions })(Devices);
