import React from 'react';
import Back from '../../Common/BackArrow';
import { useHistory } from 'react-router-dom';

import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { DisplaySvg } from '../../Common/DisplaySvg';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';
import { AnimatedPageContainer } from '../../Common/AnimatedPageContainer';
import { NdovuLogo } from '../../Common/NdovuLogo';
import elephant from '../../../images/elephantLogo.svg';

const SettlementNotification = () => {
  const history = useHistory();

  return (
    <AnimatedPageContainer>
      <Back title={'Withdrawal Details'} text={'Back'} />
      <FullWidthWrapper>
        <div className="d-flex flex-column align-items-center">
          <NdovuLogo image={elephant} />

          <div className="withdraw-title py-3">
            Where should we send your money?
          </div>
          <p className="withdraw-info">
            Please add withdrawal details to complete this operation
          </p>

          <ButtonsContainer>
            <ConfirmButton
              buttonText={'Add Withdrawal Details'}
              handleOnClick={() => history.push('/withdrawal-details')}
            />
          </ButtonsContainer>
        </div>
      </FullWidthWrapper>
    </AnimatedPageContainer>
  );
};

export default SettlementNotification;
