import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const GET_PAYMENT_MODAL = `${REACT_APP_BASE_URL}/v1/paystack/initiate-transaction`;
const VERIFY_CARD_TRANSACTION = `${REACT_APP_BASE_URL}/v1/paystack/verify-transaction/`;
export const getCardPaymentModal = async ({
  amount,
  currency,
  promoCode,
  channel,
  investmentAction,
  pocketId,
}) => {
  const response = await axios.post(GET_PAYMENT_MODAL, {
    amount: amount,
    currency,
    promoCode,
    channel,
    ...(pocketId ? { pocketId: Number(pocketId) } : {}),
    investmentAction,
  });
  return response;
};

export const verifyCardTransaction = async reference => {
  const response = await axios.get(VERIFY_CARD_TRANSACTION + reference);
  return response;
};
