import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import {
  CreateAccountType,
  kycFlowActions,
  menuNames,
} from '../../../helpers/constants';
import Back from '../../Common/BackArrow';
import { DisplaySvg } from '../../Common/DisplaySvg';

const SelectAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAccountType = accounts => {
    // to store
    localStorage.setItem('accountType', accounts.value);
    localStorage.setItem('creatingAccount', true);

    dispatch(
      kycActions?.updateKycInfo({
        kycAction: kycFlowActions.SUBMITTING_KYC_INFORMATION,
      }),
    );
    history.push(accounts.link);
  };

  const previousPage = localStorage.getItem(menuNames.MANAGE_ACCOUNT);

  return (
    <div className="mt-5">
      <Back
        title={'Select Account Type'}
        previousPageLink={previousPage ? previousPage : '/home'}
      />

      <div className="border-light-blue-600 border-radius-8 px-8 py-6">
        {CreateAccountType.map(accounts => (
          <div
            onClick={() => handleAccountType(accounts)}
            key={accounts.text}
            className="border-light-blue-600 border-radius-8 text-16 font-weight-500 my-2 px-5 py-3 cursor-pointer"
          >
            {/* <!--begin::Body--> */}
            <div className="d-flex flex-row justify-content-between align-content-center">
              <div className="text-16 font-weight-500">{accounts.text}</div>
              <div>
                <DisplaySvg svgUrl={'/media/svg/ndovu/arrow.svg'} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectAccount;
