import objectPath from 'object-path';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useHtmlClassService } from '../../_core/MetronicLayout';

import { useSelector } from 'react-redux';
import ndovuLogo from '../../../../app/icons/ndovuIconMobile.svg';
import { displayInvestorFirstName } from '../../../../app/helpers/displayInvestorFirstName';
import { DisplaySvg } from '../../../../app/modules/Common/DisplaySvg';

export function HeaderMobile() {
  const uiService = useHtmlClassService();

  const profile = useSelector(state => state.profile);
  const { investor, user } = profile;

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile'),
    };
  }, [uiService]);

  const checkCorporateCode =
    investor?.voucherCorporate && investor.voucherCorporate.logoLink !== null;

  const displayLogo = () => {
    if (investor?.originPartner?.logoUrl) {
      return (
        <div className="partner-logo-container">
          <img
            alt="logo"
            src={investor?.originPartner?.logoUrl}
            className="partner-logo-image"
          />
        </div>
      );
    } else if (checkCorporateCode) {
      return (
        <div className="partner-logo-container">
          <img
            className="partner-logo-image"
            alt="Partner logo"
            src={investor.voucherCorporate.logoLink}
          />
        </div>
      );
    } else {
      return (
        <div className="ndovu-logo-container ml-3">
          <img className="ndovu-logo-image" alt="Ndovu logo" src={ndovuLogo} />
        </div>
      );
    }
  };

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        style={{ zIndex: 400 }}
        className={`header-mobile align-items-center mobile-header-container ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        {!profile?.loading ? <Link to="/">{displayLogo()}</Link> : <div> </div>}
        {/*end::Logo*/}

        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              {/* <button id="kt_aside_mobile_toggle"> */}
              <span className="mr-5" id="kt_aside_mobile_toggle">
                {/* <img src={leftMenuIcon} alt="left menu icon" /> */}
              </span>
              {/* </button> */}
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          <span className="mr-5 text-blue text-13 font-weight-500 text-capitalize">
            {displayInvestorFirstName(
              investor?.investorType,
              user?.firstName,
              investor?.businessChamaName,
              15,
            )}
          </span>

          {/*begin::Topbar Mobile Toggle*/}
          <div id="kt_quick_user_toggle2">
            <div className="mobile-avatar-container">
              <DisplaySvg svgUrl={'/media/svg/ndovu/profile-avatar.svg'} />
            </div>
          </div>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
    </>
  );
}
